import { setSubscriber } from "../features/auth/authActions";
import axios from 'axios'
import { setAmplitudeUserProperties } from "../app/common/logging/amplitude";
export function fetchSubscriberStatusFromRevenueCat(uid, dispatch) {

    const options = {
      method: 'GET',
      url: 'https://api.revenuecat.com/v1/subscribers/' + uid,
      headers: {
        'content-type': 'application/json',
        'x-platform': 'ios',
        authorization: 'Bearer nHZBLrgBnoezywmcDwCqExTKfmBlKmJC'
      }
    };

    axios(options)
        .then(function (response) {
            const data = response.data

            setAmplitudeUserProperties({
              subscribed: data.subscriber.entitlements && Object.keys(data.subscriber.entitlements ).length > 0,
              subscribed_through_stripe: data.subscriber.subscriptions && Object.values(data.subscriber.subscriptions).length > 0 && Object.values(data.subscriber.subscriptions)[0].store == "stripe"
            })

            dispatch(setSubscriber(data.subscriber));

        })
        .catch(function (error) {
            console.log(error);
        })



}





