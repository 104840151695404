import React from 'react';
import { Menu, Container, Button } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { openSidebar, closeSidebar } from '../sidebar/sidebarReducer';
import { openModal } from '../../app/common/modals/modalReducer';
import TopicTitle from '../nav/TopicTitle';
import { setStage, undoGrade, setRandomOrder, setReviewInitialState, updateSelectedNote, setCurrentNoteIndex, previousCard } from './reviewActions';

export default function ReviewNavBar({ setFormOpen }) {
  const { authenticated } = useSelector(state => state.auth);
  const { sidebarVisible } = useSelector(state => state.sidebar);
  const { selectedTopic } = useSelector((state) => state.topic);
  const { reviewType, notes, showCurrentAnswer, stage, selectedNotes, currentNoteIndex } = useSelector((state) => state.review);

  const history = useHistory();

  const dispatch = useDispatch();


  async function toggleMenu() {
    sidebarVisible ? dispatch(closeSidebar()) : dispatch(openSidebar())
  }

  async function backTapped() {
    if (selectedTopic) {
      history.push(`/topics/${selectedTopic.id}`);
    } else {
      history.push(`/topics`);
    }
    dispatch(setReviewInitialState());

  }


 
  return (
    <Menu inverted fixed='top' style={{ height: 60, paddingLeft: 16, paddingRight: 16 }} className="reviewNavMenu">
      <Menu.Item className="reviewSidebarButton" onClick={toggleMenu} style={{ borderLeft: 0, position: "absolute", height: "100%" }}>
        <img src='/assets/menu.png' alt='logo' style={{ height: 16, width: "auto" }} />
      </Menu.Item>
      <Container className={sidebarVisible ? "sidebarVisible" : ""} style={{fontSize: 16, display: "flex", justifyContent: "space-between"}}>

        <Menu.Item onClick={backTapped} className="navBarEdgeButton"
          style={{ fontSize: 16, paddingTop: 10, paddingBottom: 10, paddingRight: 0, paddingLeft: 0, borderLeft: 0 }}>
          <img src='/assets/leftArrow.png' alt='logo' style={{ width: 21, height: "auto", paddingTop: 10, paddingBottom: 5, paddingRight: 10 }} />

          <span className="hideOnMobile">Exit review</span>

        </Menu.Item>
        {
          {
            'configuring': <ConfiguringMenuItems />,
            'reviewing': <ReviewingMenuItems />,
            'complete': <ReviewingMenuItems />,

          }[stage]
        }

      </Container>
    </Menu>
  );
}

export function CompleteMenuItems() {
  const dispatch = useDispatch();
  const { selectedTopic } = useSelector((state) => state.topic);
  return (
    <>


      <Menu.Item>
        <span className="titleTextFont" style={{ fontSize: 15, color: "#3F3F3F" }}>Complete!</span>
      </Menu.Item>

      <Menu.Item className="navBarEdgeButton" >

      </Menu.Item>


    </>
  );
}

export function ConfiguringMenuItems() {
  const dispatch = useDispatch();
  const { selectedTopic } = useSelector((state) => state.topic);
  return (
    <>


      <Menu.Item style={{ fontSize: 16, lineHeight: 1.3 }} className="reviewNavBarTitle normalTextFont">
        <span className="titleTextFont" style={{ fontSize: 15, color: "#3F3F3F", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}> {selectedTopic ? "\n" + selectedTopic.name : ""}</span>
      </Menu.Item>

      <Menu.Item className="navBarEdgeButton" >

      </Menu.Item>

    </>
  );
}

export function ReviewingMenuItems({ }) {
  const dispatch = useDispatch();
  const { selectedNotes, currentNoteIndex, stage } = useSelector(
    (state) => state.review
  );

  async function undo() {
    dispatch(previousCard());

  }

  return (
    <>
      <Menu.Item className="normalTextFont" style={{ fontSize: 16 }}>
        <span style={{ color: "#EF4136" }}>

          {stage === "complete" ? "0" : (

            (selectedNotes ? selectedNotes.length : 0) - currentNoteIndex)}{" "}
        </span>{" "}
        &nbsp;<span style={{ color: "#929292" }}> left</span>
      </Menu.Item>

      {/* <TopicTitle /> */}

      <Menu.Item
        onClick={undo}
        style={{ padding: "10px 0px", display: "flex", justifyContent: "flex-end" }}
        className="navBarEdgeButton"

      >
        <div style={{ height: "100%"}}> </div>

        <img
          src='/assets/undo.png'
          alt='logo'
          style={{
            width: 24,
            paddingTop: 10,
            paddingBottom: 10,
            margin: 0,
            display:
              stage !== "complete" && currentNoteIndex > 0 ? "inline" : "none",
          }}
        />
      </Menu.Item>
    </>
  );
}
