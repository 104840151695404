import firebase from '../config/firebase';
import { createReview } from '../common/util/util';

const db = firebase.firestore();

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  // for (const prop in data) {
  //   if (data.hasOwnProperty(prop)) {
  //     if (data[prop] instanceof firebase.firestore.Timestamp) {
  //       data[prop] = data[prop].toDate();
  //     }
  //   }
  // }

  return {
    ...data,
    id: snapshot.id,
  };
}

export function fetchTopicsFromFirestore(
  filter,
  startDate,
  limit,
  lastDocSnapshot = null
) {

  const user = firebase.auth().currentUser;

  let topicsRef = db
    .collection('topics').where('user_id', '==', user.uid);
  return topicsRef;
}


export function fetchNotesFromFirestore(
  selectedTopic,
  limit,
  lastDocSnapshot = null
) {

  const user = firebase.auth().currentUser;

  if (!user || !user.uid) {
    return;
  }
  
  const sharedTopicId = selectedTopic.shared_id

  var query = db
  .collection('notes')

  if (sharedTopicId) {

    query = query
      .where('topic_id', '==', sharedTopicId)
      .orderBy('created_at', 'desc')    
  } else {

    query = query
      .where('user_id', '==', user.uid)
      .where('topic_id', '==', selectedTopic.id)
      .orderBy('created_at', 'desc')
    
  }
  if (lastDocSnapshot) {
    query = query.startAfter(lastDocSnapshot)     
  }
  return query.limit(limit);

}

export function fetchEventsFromFirestore(
  filter,
  startDate,
  limit,
  lastDocSnapshot = null
) {
  const user = firebase.auth().currentUser;
  let eventsRef = db
    .collection('events')
    .orderBy('date')
    .startAfter(lastDocSnapshot)
    .limit(limit);
  switch (filter) {
    case 'isGoing':
      return eventsRef
        .where('attendeeIds', 'array-contains', user.uid)
        .where('date', '>=', startDate);
    case 'isHost':
      return eventsRef
        .where('hostUid', '==', user.uid)
        .where('date', '>=', startDate);
    default:
      return eventsRef.where('date', '>=', startDate);
  }
}

export function listenToEventFromFirestore(eventId) {
  return db.collection('events').doc(eventId);
}

export function listenToTopicFromFirestore(topicId) {
  return db.collection('topics').doc(topicId);
}

export function buildNote(existingNote, topic, newBody, questionText, manualImportantRanges, uploadedImage, uploadedImageDimensions, subscriberStatus) {

  // To make updated / created at the same
  const currentDate = new Date() 

  let note = {
    ...existingNote,
    body: newBody,
    manual_important_ranges: manualImportantRanges,
    subscriber_status: subscriberStatus,
    batch_inserted: false,
    updated_at: currentDate,
    topic_id: topic.id,
    topic_name: topic.name
    
  }

  // If we're editing
  if (existingNote) {
    if (existingNote.body != newBody) {
      note.google_entities = firebase.firestore.FieldValue.delete()
      note.google_salient_phrase = firebase.firestore.FieldValue.delete()
      note.google_salient_phrase_score = firebase.firestore.FieldValue.delete()
      note.google_salient_phrase_type = firebase.firestore.FieldValue.delete()
      note.special_phrase = firebase.firestore.FieldValue.delete()
      note.special_important_range_location = firebase.firestore.FieldValue.delete()
      note.special_important_range_length = firebase.firestore.FieldValue.delete()
      note.special_important_ranges = firebase.firestore.FieldValue.delete()

    }
  } else {
    note.created_at = currentDate
  }

  // If we've got an image
  if (uploadedImage && uploadedImage.fullPath) {
    note.image_attachment_full_path = uploadedImage.fullPath
    note.image_attachment_bucket = uploadedImage.bucket
    note.image_attachment_filename = uploadedImage.filename
    note.image_attachment_width = (uploadedImageDimensions && uploadedImageDimensions.width) ? uploadedImageDimensions.width : 200
    note.image_attachment_height = (uploadedImageDimensions && uploadedImageDimensions.height) ? uploadedImageDimensions.height : 200


  } else if (existingNote) {
    note.image_attachment_full_path = firebase.firestore.FieldValue.delete()
    note.image_attachment_bucket = firebase.firestore.FieldValue.delete()
    note.image_attachment_filename = firebase.firestore.FieldValue.delete()
    note.image_attachment_width = firebase.firestore.FieldValue.delete()
    note.image_attachment_height = firebase.firestore.FieldValue.delete()
  }

  if (questionText) {
    note.question_type = "question"
    note.question = questionText

    if (existingNote) {
      note.lacking_manual_updated_at = firebase.firestore.FieldValue.delete()
    }
  } else {
    note.question_type = "fill_in_the_blank"

    if (existingNote) {
      note.question = firebase.firestore.FieldValue.delete()

    }

    if (manualImportantRanges && manualImportantRanges.length > 0) {
      if (existingNote) {
        note.lacking_manual_updated_at = firebase.firestore.FieldValue.delete()
      }
    } else {
      note.lacking_manual_updated_at = currentDate
    }

  }

  return note;
}

export function addNoteToFirestore(note) {
  const user = firebase.auth().currentUser;
  return db.collection('notes').add({
    ...note,
    user_id: user.uid,
    
  });
}


export function updateNoteInFirestore(note) {
  return db.collection('notes').doc(note.id).update(note);
}


export function updateExcludeInFirestore(exclude, note, topicId) {
  return db
  .collection('topics')
  .doc(topicId)
  .set({
    excluded_notes: (exclude ? firebase.firestore.FieldValue.arrayUnion(note.id) :
    firebase.firestore.FieldValue.arrayRemove(note.id)),
    updated_at: firebase.firestore.FieldValue.serverTimestamp() 

  }, { merge: true });  
}


export function addEventToFirestore(event) {
  const user = firebase.auth().currentUser;
  return db.collection('events').add({
    ...event,
    hostUid: user.uid,
    hostedBy: user.displayName,
    hostPhotoURL: user.photoURL || null,
    attendees: firebase.firestore.FieldValue.arrayUnion({
      id: user.uid,
      displayName: user.displayName,
      photoURL: user.photoURL || null,
    }),
    attendeeIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
  });
}

export function removeFromReviewHistory(noteId, oldReviewHistory, topic) {

  // return db
  //   .collection('topics')
  //   .doc(topic.id)
  //   .set({
  //    // updated_at: Date(),
  //     review_history: {
  //       [noteId]: oldReviewHistory,

  //     },
  //   }, { merge: true });  
}

export function deleteNoteInFirestore(noteId) {
  return db.collection('notes').doc(noteId).delete();
}

export function addToReviewHistory(noteId, grade, topic) {

  var reviewHistory = []
  if (topic.review_history && topic.review_history[noteId]) {
    reviewHistory = topic.review_history[noteId]
  }

  var review = createReview(Date(), grade, reviewHistory)

  return db
    .collection('topics')
    .doc(topic.id)
    .set({
      updated_at: Date(),
      review_history: {
      [noteId]: firebase.firestore.FieldValue.arrayUnion(review),

      },
    }, { merge: true });  
}



export function updateEventInFirestore(event) {
  return db.collection('events').doc(event.id).update(event);
}

export function deleteEventInFirestore(eventId) {
  return db.collection('events').doc(eventId).delete();
}

export function cancelEventToggle(event) {
  return db.collection('events').doc(event.id).update({
    isCancelled: !event.isCancelled,
  });
}

export function updateAutoSpacedRepetition(topic, value) {
  return db.collection('topics').doc(topic.id).update({
    auto_spaced_repetition: value,
    updated_at: firebase.firestore.FieldValue.serverTimestamp() 

  });
}

export function setUserProfileData(user, justCreated) {

  let userData = {
    email: user.email,
    email_verified: user.emailVerified,
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
  }

  // Don't want to overwrite this in any case, once it's
  // true once it should never be changed.
  if (justCreated) {
    userData.created_on_web = true
    userData.needs_welcome = true

  }

  return db
    .collection('users')
    .doc(user.uid)
    .set(userData, { merge: true });  
}

export function getUserProfile(userId) {
  return db.collection('users').doc(userId);
}

export function getUserStripeSubscriptions(userId) {
  return db.collection('users').doc(userId).collection('subscriptions');
}

export async function updateUserProfile(profile) {
  const user = firebase.auth().currentUser;
  try {
    if (user.displayName !== profile.displayName) {
      await user.updateProfile({
        displayName: profile.displayName,
      });
    }
    return await db.collection('users').doc(user.uid).update(profile);
  } catch (error) {
    throw error;
  }
}

export async function getTopic(topicId) {

  const topicRef = db.collection('topics').doc(topicId);
  try {
    const topicDoc = await topicRef.get();
    return topicDoc;
  } catch (error) {
    throw error;
  }
}

export async function updateUserProfilePhoto(downloadURL, filename) {
  const user = firebase.auth().currentUser;
  const userDocRef = db.collection('users').doc(user.uid);
  try {
    const userDoc = await userDocRef.get();
    if (!userDoc.data().photoURL) {
      await db.collection('users').doc(user.uid).update({
        photoURL: downloadURL,
      });
      await user.updateProfile({
        photoURL: downloadURL,
      });
    }
    return await db.collection('users').doc(user.uid).collection('photos').add({
      name: filename,
      url: downloadURL,
    });
  } catch (error) {
    throw error;
  }
}

export function getUserPhotos(userUid) {
  return db.collection('users').doc(userUid).collection('photos');
}

export async function setMainPhoto(photo) {
  const user = firebase.auth().currentUser;
  const today = new Date();
  const eventDocQuery = db
    .collection('events')
    .where('attendeeIds', 'array-contains', user.uid)
    .where('date', '>=', today);
  const userFollowingRef = db
    .collection('following')
    .doc(user.uid)
    .collection('userFollowing');

  const batch = db.batch();

  batch.update(db.collection('users').doc(user.uid), {
    photoURL: photo.url,
  });

  try {
    const eventsQuerySnap = await eventDocQuery.get();
    for (let i = 0; i < eventsQuerySnap.docs.length; i++) {
      let eventDoc = eventsQuerySnap.docs[i];
      if (eventDoc.data().hostUid === user.uid) {
        batch.update(eventsQuerySnap.docs[i].ref, {
          hostPhotoURL: photo.url,
        });
      }
      batch.update(eventsQuerySnap.docs[i].ref, {
        attendees: eventDoc.data().attendees.filter((attendee) => {
          if (attendee.id === user.uid) {
            attendee.photoURL = photo.url;
          }
          return attendee;
        }),
      });
    }
    const userFollowingSnap = await userFollowingRef.get();
    userFollowingSnap.docs.forEach((docRef) => {
      let followingDocRef = db
        .collection('following')
        .doc(docRef.id)
        .collection('userFollowers')
        .doc(user.uid);
      batch.update(followingDocRef, {
        photoURL: photo.url
      })
    });

    await batch.commit();

    return await user.updateProfile({
      photoURL: photo.url,
    });
  } catch (error) {
    throw error;
  }
}

export function deletePhotoFromCollection(photoId) {
  const userUid = firebase.auth().currentUser.uid;
  return db
    .collection('users')
    .doc(userUid)
    .collection('photos')
    .doc(photoId)
    .delete();
}

export function addUserAttendance(event) {
  const user = firebase.auth().currentUser;
  return db
    .collection('events')
    .doc(event.id)
    .update({
      attendees: firebase.firestore.FieldValue.arrayUnion({
        id: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL || null,
      }),
      attendeeIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
    });
}

export async function cancelUserAttendance(event) {
  const user = firebase.auth().currentUser;
  try {
    const eventDoc = await db.collection('events').doc(event.id).get();
    return db
      .collection('events')
      .doc(event.id)
      .update({
        attendeeIds: firebase.firestore.FieldValue.arrayRemove(user.uid),
        attendees: eventDoc
          .data()
          .attendees.filter((attendee) => attendee.id !== user.uid),
      });
  } catch (error) {
    throw error;
  }
}

export function getUserEventsQuery(activeTab, userUid) {
  let eventsRef = db.collection('events');
  const today = new Date();
  switch (activeTab) {
    case 1: // past events
      return eventsRef
        .where('attendeeIds', 'array-contains', userUid)
        .where('date', '<=', today)
        .orderBy('date', 'desc');
    case 2: // hosting
      return eventsRef.where('hostUid', '==', userUid).orderBy('date');
    default:
      return eventsRef
        .where('attendeeIds', 'array-contains', userUid)
        .where('date', '>=', today)
        .orderBy('date');
  }
}

export async function createTopicsInBatch(topics) {
  const user = firebase.auth().currentUser;
  const batch = db.batch();
  try {
    topics.forEach((value, index) => {

      const newTopicReference = db.collection('topics').doc();

      batch.set(
        newTopicReference,
        value
      );

    })
    return await batch.commit();
  } catch (error) {
    throw error;
  }
}

export async function followUser(profile) {
  const user = firebase.auth().currentUser;
  const batch = db.batch();
  try {
    batch.set(
      db
        .collection('following')
        .doc(user.uid)
        .collection('userFollowing')
        .doc(profile.id),
      {
        displayName: profile.displayName,
        photoURL: profile.photoURL,
        uid: profile.id,
      }
    );
    batch.update(db.collection('users').doc(user.uid), {
      followingCount: firebase.firestore.FieldValue.increment(1),
    });
    return await batch.commit();
  } catch (error) {
    throw error;
  }
}

export async function unfollowUser(profile) {
  const user = firebase.auth().currentUser;
  const batch = db.batch();
  try {
    batch.delete(
      db
        .collection('following')
        .doc(user.uid)
        .collection('userFollowing')
        .doc(profile.id)
    );

    batch.update(db.collection('users').doc(user.uid), {
      followingCount: firebase.firestore.FieldValue.increment(-1),
    });

    return await batch.commit();
  } catch (error) {
    throw error;
  }
}

export function getFollowersCollection(profileId) {
  return db.collection('following').doc(profileId).collection('userFollowers');
}

export function getFollowingCollection(profileId) {
  return db.collection('following').doc(profileId).collection('userFollowing');
}

export function getFollowingDoc(profileId) {
  const userUid = firebase.auth().currentUser.uid;
  return db
    .collection('following')
    .doc(userUid)
    .collection('userFollowing')
    .doc(profileId)
    .get();
}


export function addFolderToUser(uid, folderName) {
  return db
    .collection('users')
    .doc(uid)
    .set({
      folders: firebase.firestore.FieldValue.arrayUnion(folderName) ,
    }, { merge: true });  
}

export function removeFolderFromUser(uid, folderName, topics) {

  const batch = db.batch();
  batch.update(
    db
      .collection('users')
      .doc(uid),
    {
      folders: firebase.firestore.FieldValue.arrayRemove(folderName)

    }
  );

  topics.forEach(function (topic, index) {
    batch.update(
      db
        .collection('topics')
        .doc(topic.id),
      {
        folders: firebase.firestore.FieldValue.arrayRemove(folderName)
  
      }
    );
  });
  return batch.commit();
}

export function renameFolderFromUser(uid, oldFolderName, newFolderName, topics) {

  const batch = db.batch();
  batch.update(
    db
      .collection('users')
      .doc(uid),
    {
      folders: firebase.firestore.FieldValue.arrayUnion(newFolderName)

    }
  );

  batch.update(
    db
      .collection('users')
      .doc(uid),
    {
      folders: firebase.firestore.FieldValue.arrayRemove(oldFolderName)

    }
  );

  topics.forEach(function (topic, index) {

    batch.update(
      db
        .collection('topics')
        .doc(topic.id),
      {
        folders: firebase.firestore.FieldValue.arrayRemove(oldFolderName)
  
      }
    );

    batch.update(
      db
        .collection('topics')
        .doc(topic.id),
      {
        folders: firebase.firestore.FieldValue.arrayUnion(newFolderName)
  
      }
    );
  });
  return batch.commit();
} 

export function addFolderToTopic(topicId, folderName) {
  return db
    .collection('topics')
    .doc(topicId)
    .set({
      folders: firebase.firestore.FieldValue.arrayUnion(folderName) ,
      updated_at: firebase.firestore.FieldValue.serverTimestamp() 

    }, { merge: true });  
}


export function removeFolderFromTopic(topicId, folderName) {
  return db
    .collection('topics')
    .doc(topicId)
    .set({
      folders: firebase.firestore.FieldValue.arrayRemove(folderName) ,
      updated_at: firebase.firestore.FieldValue.serverTimestamp() 

    }, { merge: true });  
}

export function buildNewTopicFromSharedTopic(topic) {
  const user = firebase.auth().currentUser;


  let sharedTopic = {
    name: topic.name,
    shared_id: topic.id,
    shared_created_at: topic.created_at,
    note_count: topic.note_count ?? null,
    how_to: topic.how_to  ?? null,
    example: topic.example  ?? null,
    user_id: user.uid,
    created_at: new Date()
  }
  return sharedTopic;
}

export async function createSharedTopic(topic) {

  const newTopic = buildNewTopicFromSharedTopic(topic);

  const newTopicReference = db.collection('topics').doc();

  await newTopicReference.set(newTopic);

  newTopic.id = newTopicReference.id
  return newTopic
}


export function addTopicToFirestore(topic) {
  const user = firebase.auth().currentUser;

  let topicRef = db.collection('topics').doc()
  topicRef.set({
    ...topic,
    user_id: user.uid,
    created_at: new Date(),
  });

  return topicRef
}

export function setNeedsWelcomeInFirestore(needsWelcome) {
  const user = firebase.auth().currentUser;

  const userRef = db.collection('users').doc(user.uid);

  return userRef.update({ needs_welcome: needsWelcome });

}

export function incrementTextScanCount() {
  const user = firebase.auth().currentUser;

  const userRef = db.collection('users').doc(user.uid);

  return userRef.update({ text_scan_count: firebase.firestore.FieldValue.increment(1) });

}

export function incrementNoteCount(topicId, incrementValue) {
  const topicRef = db.collection('topics').doc(topicId);

  return topicRef.update({ note_count: firebase.firestore.FieldValue.increment(incrementValue), updated_at: firebase.firestore.FieldValue.serverTimestamp() });

}

export function renameTopic(topicId, newName) {
  return db
    .collection('topics')
    .doc(topicId)
    .set({
      name: newName,
      updated_at: firebase.firestore.FieldValue.serverTimestamp() 
    }, { merge: true });  
}


export function updateTopicAttributes(topicId, newAttributes) {
  return db
    .collection('topics')
    .doc(topicId)
    .set(newAttributes, { merge: true });  
}

export function deleteTopicInFirestore(topicId) {
  return db.collection('topics').doc(topicId).delete();
}