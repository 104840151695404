import {
    CREATE_NOTE,
    UPDATE_NOTE,
    DELETE_NOTE,
    FETCH_NOTES,
    LISTEN_TO_NOTE_CHAT,
    CLEAR_COMMENTS,
    LISTEN_TO_SELECTED_NOTE,
    CLEAR_NOTES,
    SET_FILTER,
    SET_START_DATE,
    RETAIN_STATE,
    CLEAR_SELECTED_NOTE,
    SET_NOTES,
  } from './noteConstants';
  
  const initialState = {
    notes: [],
    fetchedNoNotes: false,
    moreNotes: true,
    selectedNote: null,
    notesLastVisible: {'created_at': new Date().setFullYear(new Date().getFullYear() + 1000) },
    notesStartDate: new Date(),
    retainState: false,
  };
  
  export default function noteReducer(state = initialState, { type, payload }) {
    switch (type) {
      case CREATE_NOTE:
        return {
          ...state,
          notes: [...state.notes, payload],
        };
      case UPDATE_NOTE:
        return {
          ...state,
          notes: [
            ...state.notes.filter((evt) => evt.id !== payload.id),
            payload,
          ],
        };
      case DELETE_NOTE:
        return {
          ...state,
          notes: [...state.notes.filter((evt) => evt.id !== payload)],
        };
      case SET_NOTES:
        return {
          ...state,
          notes: payload,
          fetchedNoNotes: payload.length === 0
        };           
      case FETCH_NOTES:
        return {
          ...state,
          fetchedNoNotes: payload.notes.length === 0,
          notes: [...payload.notes],
          moreNotes: payload.moreNotes,
          notesLastVisible: payload.notesLastVisible,
        };
      case LISTEN_TO_NOTE_CHAT:
        return {
          ...state,
          comments: payload,
        };
      case CLEAR_COMMENTS:
        return {
          ...state,
          comments: [],
        };
      case LISTEN_TO_SELECTED_NOTE:
        return {
          ...state,
          selectedNote: payload,
        };
      case CLEAR_SELECTED_NOTE:
        return {
          ...state,
          selectedNote: null,
        };
      case CLEAR_NOTES:
        return {
          ...state,
          fetchedNoNotes: false,
          notes: [],
          moreNotes: true,
          notesLastVisible: {'created_at': new Date().setFullYear(new Date().getFullYear() + 1000) },
        };
      case SET_FILTER:
        return {
          ...state,
          retainState: false,
          moreNotes: true,
          filter: payload,
        };
      case SET_START_DATE:
        return {
          ...state,
          retainState: false,
          moreNotes: true,
          startDate: payload,
        };
      case RETAIN_STATE:
        return {
          ...state,
          retainState: true,
        };
      default:
        return state;
    }
  }
  