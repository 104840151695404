import React from 'react';
import { useField } from 'formik';
import { FormField, Label } from 'semantic-ui-react';

export default function MyTextInput({label, ...props}) {
    const [field, meta] = useField(props);
    return (
        <FormField error={meta.touched && !!meta.error}  style={{marginBottom: 20}} >
            
            <input {...field} {...props}  style={{height: 44,  backgroundColor: "#FAFAFA", borderRadius: 22}} className="textInput" />
            {meta.touched && (meta.value != meta.initialValue) && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </FormField>
    )
}