import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup, Image, Form, Label, Divider } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link, useHistory } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromTopic, updateTopicAttributes, setNeedsWelcomeInFirestore } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { Formik } from "formik";
import * as Yup from 'yup';
import firebase, { remoteConfig } from '../../app/config/firebase';
import { updateUserEmail, registerInFirebaseAnonyously } from '../../app/firestore/firebaseService';
import MyTextInput from "../../app/common/form/MyTextInput";
import { logEvent } from "../../app/common/logging/logging";
import ReviewCarousel from "./ReviewCarousel";
import { useEffect } from "react";

export default function WelcomeModal() {

    const dispatch = useDispatch();

    const { selectedTopic } = useSelector((state) => state.topic);
    const history = useHistory();

    // If you only want to run the function given to useEffect after the initial render, you can give it an empty array as second argument.  
    useEffect(() => {
        setNeedsWelcomeInFirestore(false);
        logEvent("Opening View Seen")

    }, []);


    async function showSubWallIfAppropriate() {
        if (remoteConfig().getValue('showSubscribeViewOnWelcome').asBoolean()) {

            dispatch(openModal({ modalType: 'SubscribeWall' , modalProps: { initialSubscribeView: true}}))
            
        }
    }


    async function getStarted() {

        showSubWallIfAppropriate();
        logEvent("Opening Done Tapped")
    }


    async function signIn() {


        dispatch(closeModal());
        dispatch(openModal({ modalType: 'LoginForm', modalProps: { notClosable: false } }))
        logEvent("Opening Login Tapped")

    }


    return (
        <ModalWrapper onClose={getStarted}
            size="tiny" notClosable={false} maxWidth={410} style={{ borderRadius: 50 }}>


            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 8, borderRadius: 50 }}>
                <div style={{}}>

                    {/* <Image
                        src='/assets/illustration1.png'
                        style={{ height: "auto", width: 180, paddingTop: 10, paddingBottom: 10 }}
                    /> */}

                    <Image
                        src='/assets/logo.png'
                        style={{ height: "auto", width: 60, paddingTop: 0, paddingBottom: 10 }}
                    /> 

                </div>
                <div className="titleTextFont" style={{ paddingTop: 16, fontSize: 23, textAlign: "center" }}>
                    Welcome to  Notefuel!
                    </div>
                <div className="normalTextFont" style={{
                    paddingTop: 16, fontSize: 17, color: "#3F3F3F", lineHeight: "1.4285em",
                    fontWeight: "400", textAlign: "center"
                }}>
                    {/* Notefuel is a note-taking app specifically for learning. Powerful, yet simple, Notefuel helps you take notes you'll actually review and remember. */}
                    Notefuel is the best note-taking app for <span>learning</span>, period. Simple, but powerful, it makes retaining knowledge easy.
                    {/* <br></br>
                    <br></br> */}
                    

                    </div>

                {/* <Formik
                    initialValues={{ email: '' }}
                    validationSchema={Yup.object({
                        email: Yup.string().required().email()
                    })}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        try {

                            // If the user
                            if (values.email) {
                                // Create an account with email
                                await registerInFirebaseAn(values.email);

                            } else {
                                // Create an accoutn anonymously.
                                await registerInFirebaseAnonymously();

                            }
                            setSubmitting(false);
                            dispatch(closeModal());
                        } catch (error) {

                            // if (error.code === "auth/requires-recent-login") {
                            //     toast.error(error.message);

                            // } else {
                            //     setErrors({auth: 'Email is invalid.'});
                            // }
                            setErrors({ auth: error.message });

                            setSubmitting(false);
                        }
                    }}
                >

                    {({ isSubmitting, isValid, dirty, errors }) => (
                        <Form className='ui form' style={{width: "100%", paddingTop: 20}}>
                            <div>
                                Providing email is optional - for account recovery and cross-device sync. Use Notefuel anonymously if you want!)
                            </div>
                            <MyTextInput autoFocus name='email' placeholder={'Email Address'} />
                            {errors.auth && <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />}
                            <Button className="secondaryButton"
                                style={{ backgroundColor: "#fff", height: 50, borderRadius: 25, width: 200, boxShadow: "0 5px 10px 0 rgba(0,0,0,0.10)" }}
                                loading={isSubmitting}
                                disabled={!isValid || !dirty || isSubmitting}
                                type='submit'
                                fluid
                                size='large'
                                content='Get started'
                            />

                        </Form>
                    )}
                </Formik> */}

                <div className="normalTextFont" style={{
                    paddingTop: 24, fontSize: 16, color: "#3F3F3F", lineHeight: "1.4285em",
                    fontWeight: "400"
                }}>
                    <Button
                        onClick={getStarted}
                        style={{ color: "#fff", height: 50, borderRadius: 25, width: 200, fontSize: 16 }}
                        className='submitButton'
                    >
                        <div>Get started</div>

                    </Button>
                </div>
                <div className="normalTextFont" style={{
                    paddingTop: 8, fontSize: 13, color: "#3F3F3F", lineHeight: "1.4285em",
                    fontWeight: "400", textAlign: "center", color: "#6D6D6D"
                }}>
                   You're already signed in to a fresh account - try Notefuel quickly & only provide your email if you like it!
                </div>

                <div className="normalTextFont" style={{
                    paddingTop: 32, fontSize: 15, color: "#3F3F3F", lineHeight: "1.4285em",
                    fontWeight: "400", textAlign: "center"
                }}>
                    Already have an account? <a onClick={signIn} style={{ color: "#EF4136", cursor: "pointer" }}>Sign in</a>
                </div>



                {((remoteConfig().getValue('showReviewModuleInWelcome').asBoolean()) && window.innerHeight > 850) &&
                <>
                    <div className="normalTextFont" style={{ color: "#6D6D6D", paddingTop: 32, fontSize: 17, textAlign: "left", borderTop: "1px solid #F1F1F1", marginTop: 32, width: "100%" }}>
                        Here's what  users say:

 

                    </div>
                                            <ReviewCarousel></ReviewCarousel>
                                            </>

                }

            </div>


        </ModalWrapper>
    );
}
