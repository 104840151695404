import React from 'react';
import { useSelector } from 'react-redux';
import TestModal from '../../../features/sandbox/TestModal';
import LoginForm from '../../../features/auth/LoginForm';
import RegisterForm from '../../../features/auth/RegisterForm';
import ChangeEmailForm from '../../../features/auth/ChangeEmailForm';
import SubscribeWall from '../../../features/auth/SubscribeWall';
import EditorModal from '../../../features/editor/EditorModal';
import CreateFolderModal from '../../../features/folders/CreateFolderModal';
import RenameFolderModal from '../../../features/folders/RenameFolderModal';
import AddToFoldersModal from '../../../features/folders/AddToFoldersModal';
import AddTopicModal from '../../../features/topics/AddTopicModal';
import RenameTopicModal from '../../../features/topics/RenameTopicModal';
import VisibilityModal from '../../../features/topics/VisibilityModal';
import SpacedRepetitionModal from '../../../features/topics/SpacedRepetitionModal';
import WelcomeModal from '../../../features/onboarding/WelcomeModal';
import StripeSubscriptionModal from '../../../features/auth/StripeSubscriptionModal';

export default function ModalManager() {
  const modalLookup = {
      TestModal,
      LoginForm,
      RegisterForm,
      ChangeEmailForm,
      SubscribeWall,
      EditorModal,
      CreateFolderModal,
      RenameFolderModal,
      AddToFoldersModal,
      AddTopicModal,
      RenameTopicModal,
      VisibilityModal,
      SpacedRepetitionModal,
      WelcomeModal,
      StripeSubscriptionModal
  };
  const currentModal = useSelector((state) => state.modals);
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
