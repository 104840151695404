import React, { useState } from 'react';
import { Menu, Container, Button, Input, Checkbox } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { openSidebar, closeSidebar } from '../sidebar/sidebarReducer';
import { openModal } from '../../app/common/modals/modalReducer';
import TopicTitle from '../nav/TopicTitle';
import { setIncludePublic, setHits } from './searchActions';
import { logEvent } from '../../app/common/logging/logging';

export default function SearchNavBar({ setFormOpen }) {

  const { sidebarVisible } = useSelector(state => state.sidebar);
  const { includePublic, client, publicClient } = useSelector((state) => state.search);
  const { selectedTopic } = useSelector((state) => state.topic);

  const [latestQuery, setLatestQuery] = useState("");


  const history = useHistory();

  const dispatch = useDispatch();

  async function toggleMenu() {
    sidebarVisible ? dispatch(closeSidebar()) : dispatch(openSidebar())
  }

  async function backTapped() {

    dispatch(setIncludePublic(false));
    dispatch(setHits([]));


    if (selectedTopic) {
      history.push(`/topics/${selectedTopic.id}`);
    } else {
      history.push(`/topics`);
    }

    logEvent("Exit Search Tapped")

  }

  async function searchChanged(event, data) {
    let query = data.value
    setLatestQuery(query)
    conductSearch(query, false, null)
  }

  // Force public is used to make this call in the event that
  // include public was just turned on, and dispatch might not have updated
  // yet.
  async function conductSearch(query, includePublicJustChanged, includePublicNewValue) {

    if (!query || query.match(/^\s+$/) !== null) {
      dispatch(setHits([]))

      return;
    }

    let queryClient
    if (includePublicJustChanged) {
      queryClient = includePublicNewValue ? publicClient : client
    } else {
      queryClient = includePublic ? publicClient : client
    }

    if (!queryClient) {
      return;
    }

    const index = queryClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

    index.search(query).then(({ hits }) => {
      dispatch(setHits(hits))

    });

  }


  async function includePublicChanged(event, data) {
    dispatch(setIncludePublic(data.checked))
    dispatch(setHits([]))
    conductSearch(latestQuery, true, data.checked);
    logEvent("Search Public Switch Tapped", { new_searching_public: data.checked })

  }


  return (
    <Menu inverted fixed='top' style={{ height: 60, paddingLeft: 16, paddingRight: 16 }} className="searchNavMenu">
      <Menu.Item className="reviewSidebarButton" onClick={toggleMenu} style={{ borderLeft: 0, position: "absolute", height: "100%" }}>
        <img src='/assets/menu.png' alt='logo' style={{ height: 16, width: "auto" }} />
      </Menu.Item>
      <Container className={sidebarVisible ? "sidebarVisible" : ""} style={{fontSize: 16, display: "flex", justifyContent: "space-between"}}>

        <Menu.Item onClick={backTapped} 
           style={{flexGrow: 0, paddingTop: 10, paddingBottom: 10, paddingRight: 0, paddingLeft: 0, borderLeft: 0 , marginRight: 0}}>
          <img src='/assets/leftArrow.png' alt='logo' style={{ width: 21, height: "auto", paddingTop: 10, paddingBottom: 5, paddingRight: 10 }} />

          <span className="hideOnMobile">Exit search</span>

        </Menu.Item>

        <Menu.Item style={{flexGrow: 3, width: 60}}>
          <Input icon='search' autoFocus placeholder='Search' onChange={searchChanged} style={{width: "100%"}}/>
        </Menu.Item>

        <Menu.Item style={{flexGrow: 0, padding: 0}}>

          <Checkbox
            className='right floated'
            toggle
            onChange={includePublicChanged}
            checked={includePublic}
          ></Checkbox>

          <div style={{ whiteSpace: "nowrap", marginLeft: 6, fontSize: '13px', color: '#B3B3B3' }}>

            Search public <span className="hideOnMobile">notes</span>

            </div>
        </Menu.Item>

      </Container>
    </Menu>
  );
}



