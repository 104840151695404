import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import { Button, Label } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { updateUserEmail } from '../../app/firestore/firebaseService';
import firebase from '../../app/config/firebase';
import { addFolderToUser, addFolderToTopic, addTopicToFirestore, dataFromSnapshot } from '../../app/firestore/firestoreService';
import { setFilter, setNeedsTopicFetch } from '../topics/topicActions';
import { useHistory } from 'react-router-dom';
import { logEvent } from '../../app/common/logging/logging';

export default function AddTopicModal() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { filter, filterFolder } = useSelector((state) => state.topic);


    return (
        <ModalWrapper size='mini' header="Enter the new topic's name">
            <Formik
                initialValues={{ topicName: '' }}
                validationSchema={Yup.object({
                    topicName: Yup.string().required()
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {

                        let newTopic = {
                            name: values.topicName, note_count: 0
                        }

                        if (filter == "folder") {
                            newTopic.folders = [filterFolder]
                        }
                        dispatch(setNeedsTopicFetch(true));

                        const newlyCreatedTopicRef =  addTopicToFirestore(newTopic);
                        console.log("Newly created topic ref id: " + newlyCreatedTopicRef.id)
                        history.push("/topics/" + newlyCreatedTopicRef.id)

                         dispatch(closeModal());
                         
                         logEvent("Topic List Created New Topic", {topic_name: values.topicName, topic_id:  newlyCreatedTopicRef.id});

                    } catch (error) {

                        setErrors({ auth: error.message });

                        setSubmitting(false);
                    }
                }}
            >

                {({ isSubmitting, isValid, dirty, errors }) => (
                    <Form className='ui form'>
                        <MyTextInput autoFocus name='topicName' placeholder={""} />
                        {errors.auth && <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />}
                        <Button className="submitButton"
                            style={{ color: "white", height: 44, borderRadius: 22 }}
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type='submit'
                            fluid
                            size='large'
                            content='Create'
                        />

                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    )
}