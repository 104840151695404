import React from 'react';
import { Segment } from 'semantic-ui-react';
import { format } from 'date-fns';
import { noteBodyJSX } from '../../app/common/util/util';
import { useDispatch } from 'react-redux';
import { openModal } from '../../app/common/modals/modalReducer';
import { useEffect } from 'react';
import { useState } from 'react';
import firebase from '../../app/config/firebase';
import { useHistory } from 'react-router-dom';
import { setHits, setIncludePublic } from './searchActions';
import { logEvent } from '../../app/common/logging/logging';

export default function SearchResultItem({ hit }) {

    const history = useHistory();
    const dispatch = useDispatch();

    async function handleClick(topicId) {

        dispatch(setIncludePublic(false));
        dispatch(setHits([]));
        history.push('/topics/' + topicId);
        logEvent("Search Selected Topic", {topic_id: topicId})
    }

    let noteCount = hit.notes ? hit.notes.length : 0
    let noteCountString = noteCount + (noteCount == 1 ? " note" : " notes")

    let noteToHighlightCreatedAt
    var noteToHighlight
    if (hit._highlightResult.notes) {

        hit._highlightResult.notes.some(function (note, index) {

            if ((note.body && note.body.matchLevel == "full") || (note.question && note.question.matchLevel == "full")) {
                //noteToHighlight = note
                noteToHighlight = hit.notes[index]
                noteToHighlightCreatedAt = new firebase.firestore.Timestamp(noteToHighlight.created_at._seconds, noteToHighlight.created_at._nanoseconds)
                return true;
            }
            return false;
        });

    }

    return (
        <div className="searchResultItem" key={hit.firestore_id} onClick={() => handleClick(hit.firestore_id)}>
            <Segment.Group style={{ boxShadow: "0 10px 20px 0 rgba(0,0,0,0.05)", marginTop: 0, marginBottom: 0 , paddingTop: 16}}>

                <Segment className="titleTextFont" style={{ fontSize: 15}} >
                    <div>
                        {hit.name}
                    </div>

                    <div style={{ fontSize: '11px', color: '#B3B3B3', display: "flex", justifyContent: "space-between" }}>
                        {noteCountString}
                    </div>

                </Segment>


                {noteToHighlight &&
                <div style={{paddingTop: 16, paddingBottom: 16, paddingLeft: 16, paddingRight: 16}}>
                    <Segment.Group style={{ boxShadow: "0 3px 3px 0 rgba(0,0,0,0.05)", borderTop: 0}}>

                        <Segment style={{ fontSize: '11px', color: '#B3B3B3', display: "flex", justifyContent: "space-between" }} className="titleTextFont">
                            <div>
                            {format(noteToHighlightCreatedAt.toDate(), 'MMMM d, yyyy · h:mm a')}
                            </div>

                        </Segment>
                        {noteToHighlight.question && (
                            <Segment clearing className="questionSegment" >
                                <span className="titleTextFont">Question: </span><span className="normalTextFont" style={{ color: "#6D6D6D"}}>{noteToHighlight.question}</span>

                            </Segment>
                        )}
                        <Segment clearing style={{ paddingTop: '0px', borderTop: "none" }}>
                            {noteBodyJSX(noteToHighlight, "fitdm", true, hit.name == "The Notefuel User Guide")}


                        </Segment>

                    </Segment.Group>
                    </div>
                }

            </Segment.Group>
        </div>
    );
}
