import firebase from '../config/firebase';
import { setUserProfileData, getTopic, addTopicToFirestore, dataFromSnapshot, createSharedTopic, createTopicsInBatch, buildNewTopicFromSharedTopic } from './firestoreService';
import { toast } from 'react-toastify';
import { remoteConfig } from 'firebase';
import { setOnboardingNeeded } from '../../features/onboarding/onboardingActions';

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map(e => Object.assign({}, e[1], { id: e[0] }))
  }
}

export function signInWithEmail(creds) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(creds.email, creds.password);
}

export function signOutFirebase() {
  return firebase.auth().signOut();
}


export async function registerInFirebaseAnonyously(dispatch) {

  try {
    const result = await firebase
      .auth()
      .signInAnonymously();

    // Actually need to make sure we wait here, because
    // our multi device sub wall relies on having fetched
    // the user profile data before we check subscription data.
    await setUserProfileData(result.user, true);

    const sharedExampleTopics = remoteConfig().getValue('sharedExampleTopics').asString().split(",")
    const sharedHowToTopic = remoteConfig().getValue('sharedHowToTopic').asString();

    sharedExampleTopics.push(sharedHowToTopic);

    const topicsToCreate = []

    try {

      for (let i = 0; i < sharedExampleTopics.length; i++) {

        // Let this be handled by normal left sidebar flow.

        const sharedTopicSnapshot = await getTopic(sharedExampleTopics[i]);
        const sharedTopic = dataFromSnapshot(sharedTopicSnapshot);
        topicsToCreate.push(buildNewTopicFromSharedTopic(sharedTopic));
      }

    } catch (error) {
      throw error;
    }
    console.log("Creating topics in batch")

    await createTopicsInBatch(topicsToCreate);

    // Once we've created example topics, then we can go ahead and
    // dispatch what will allow the app to be initialized
    console.log("Dispatched onboarding no longer needed")

    dispatch(setOnboardingNeeded(false));



  } catch (error) {
    throw error;
  }

}

export async function addSharedTopic(topicId, existingTopics) {

  var existingTopic

  existingTopics.some(function (topic, index) {

    if (topic.id === topicId || topic.shared_id === topicId) {
      existingTopic = topic
      return true;
    }
    return false;
  });

  // If we have an existing topic, then either open it or do nothing.
  if (existingTopic) {
    return;
  }

  try {
    const topicSnapshot = await getTopic(topicId);

    const topic = dataFromSnapshot(topicSnapshot);

    await createSharedTopic(topic);


  } catch (error) {
    toast.error("Unable to open topic. It may have been made private or deleted.");
    throw error;
  }



}



export async function registerInFirebasePasswordless(creds) {

  var actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: 'http://localhost:3000/signing_in',
    //url: 'https://whistler-51a6d.firebaseapp.com/signing_in',

    // This must be true.
    handleCodeInApp: true,
    iOS: {
      bundleId: 'com.boiledocean.notefuel'
    },
    dynamicLinkDomain: 'whistlerdev.page.link'
  };

  try {
    const result = await firebase
      .auth()
      .sendSignInLinkToEmail(creds.email, actionCodeSettings);
    ;

    window.localStorage.setItem('emailForSignIn', creds.email);

    //var email = window.localStorage.getItem('emailForSignIn');

  } catch (error) {
    throw error;
  }
}

export async function completeSignInPasswordlessIfAppropriate() {

  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    var email = window.localStorage.getItem('emailForSignIn');

    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      toast.error("You need to request the link from this device.");
      return;
      //email = window.prompt('Please provide your email for confirmation');
    }

    try {
      const result = await firebase.auth().signInWithEmailLink(email, window.location.href);

      //window.localStorage.removeItem('emailForSignIn');

      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser


      return await setUserProfileData(result.user);

    } catch (error) {
      throw error;
    }


  }

}

export async function registerInFirebase(creds) {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(creds.email, creds.password);
    await result.user.updateProfile({
      displayName: creds.displayName,
    });
    return await setUserProfileData(result.user);
  } catch (error) {
    throw error;
  }
}

export async function socialLogin(selectedProvider) {
  let provider;
  if (selectedProvider === 'facebook') {
    provider = new firebase.auth.FacebookAuthProvider();
  }
  if (selectedProvider === 'google') {
    provider = new firebase.auth.GoogleAuthProvider();
  }
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    if (result.additionalUserInfo.isNewUser) {
      await setUserProfileData(result.user);
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export function updateUserPassword(creds) {
  const user = firebase.auth().currentUser;
  return user.updatePassword(creds.newPassword1);
}

export function updateUserEmail(creds) {
  const user = firebase.auth().currentUser;
  return user.updateEmail(creds.email);
}

export function uploadToFirebaseStorage(file, filename) {
  const user = firebase.auth().currentUser;
  const storageRef = firebase.storage().ref();
  return storageRef.child(`images/${filename}`).put(file);

  //return storageRef.child(`${user.uid}/user_images/${filename}`).put(file);
}

export function deleteFromFirebaseStorage(filename) {
  const userUid = firebase.auth().currentUser.uid;
  const storageRef = firebase.storage().ref();
  const photoRef = storageRef.child(`images/${filename}`);

  //  const photoRef = storageRef.child(`${userUid}/user_images/${filename}`);
  return photoRef.delete();
}

export function addEventChatComment(eventId, values) {
  const user = firebase.auth().currentUser;
  const newComment = {
    displayName: user.displayName,
    photoURL: user.photoURL,
    uid: user.uid,
    text: values.comment,
    date: Date.now(),
    parentId: values.parentId
  }
  return firebase.database().ref(`chat/${eventId}`).push(newComment);
}

export function getEventChatRef(eventId) {
  return firebase.database().ref(`chat/${eventId}`).orderByKey()
}

export function getUserFeedRef() {
  const user = firebase.auth().currentUser;
  return firebase.database().ref(`posts/${user.uid}`).orderByKey().limitToLast(5)
}