import React from 'react';

import LoadingComponent from '../../app/layout/LoadingComponent';
import { useHistory } from 'react-router-dom';
import { completeSignInPasswordlessIfAppropriate } from '../../app/firestore/firebaseService';
import { useEffect } from 'react';

export default function SigningInLoader({ }) {

    const history = useHistory();

    async function signInAndRedirectHome() {
        await  completeSignInPasswordlessIfAppropriate();
        history.push('/')
    }

    useEffect(() => {
        
        signInAndRedirectHome()
    
        return () => {
    
        }
      }, [history]);
    

  return (
    <LoadingComponent content='Signing in...' /> 
  );
}

