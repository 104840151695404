import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { getRecentDaysOfReviews } from '../../app/common/util/util'

export default function ReviewHistoryGraph({postReview}) {

    const { selectedTopic } = useSelector((state) => state.topic);

    let { maxNumberOfNotes, recentDays } = getRecentDaysOfReviews(selectedTopic)

    let lastReviewedRecallRate = null
    let lastDay = null
    if (recentDays && recentDays.length > 0) {
        lastDay = recentDays[0]
        lastReviewedRecallRate = Math.round(100 * lastDay.recalled / (lastDay.recalled + lastDay.forgotten))

    } else {
        return null;
    }

    return (

 


        <div style={{ height: 100, backgroundColor: "", position: "absolute", height: 60, width: 100, bottom: 0, right: 0, marginRight: 16, marginLeft: 20, marginBottom: 40, lineHeight: "1.15" }}>
            {lastReviewedRecallRate &&
                <>

                    <div style={{ width: "100%", height: 40, backgroundColor: "#3F3F3F", top: -54, right: -4, position: "absolute", borderRadius: 6 , color: "#fff", padding: 6}}>

                        <div>
                            <div style={{ color:"white", opacity: 0.5, fontSize: 11}}> {postReview ? "Today's total" : "Last reviewed"}</div>
                            <div style={{ color:"white", fontSize: 11, paddingTop: 4}}>{lastReviewedRecallRate}% recalled</div>
                        </div>
                        <div style={{ width: 0, height: 0, backgroundColor: "", bottom: -6, right: 4, position: "absolute",
                    borderLeft: "4px solid transparent",
    borderRight: "4px solid transparent",
    borderTop: "6px solid #3F3F3F"}}>

                    

                    </div>


                    </div>


                    <div style={{ width: "100%", height: 1, opacity: 0.5, backgroundColor: "#FFF", top: 0, left: 0, position: "absolute" }}>
                    </div>
                    <div style={{ width: 25, height: 11, fontSize: 11, backgroundColor: "", left: -30, top: -5, position: "absolute", color: "#FFF", textAlign: "right" }}>
                        {maxNumberOfNotes}
                    </div>
                </>
            }
                {recentDays.map((recentDay, index) => (


                    <div key={format(recentDay.date, 'MM/dd/yy')} style={{ width: 9, height: 100 * (recentDay.forgotten + recentDay.recalled) / maxNumberOfNotes + "%", backgroundColor: "purple", right: 18 * index, bottom: 0, position: "absolute" }}>


                        <div style={{ width: 9, height: (100 * recentDay.recalled / (recentDay.forgotten + recentDay.recalled)) + "%", backgroundColor: "white", right: 0, bottom: 0, position: "absolute" }}>
                        </div>
                        <div style={{ width: 9, height: (100 * recentDay.forgotten / (recentDay.forgotten + recentDay.recalled)) + "%", backgroundColor: "#3F3F3F", right: 0, bottom: (100 * recentDay.recalled / (recentDay.forgotten + recentDay.recalled)) + "%", position: "absolute" }}>
                        </div>
                        <div style={{ transform: "rotate(70deg)", fontSize: 10, position: "absolute", left: -4, bottom: -20, color: "#FFF" }}>
                            {format(recentDay.date, 'M/d')}

                        </div>

                    </div>

                ))
                }

            </div>

    );
}