import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import LogoLottie from './LogoLottie';

export default function LoadingComponent({inverted = true, content = 'Loading...'}) {
    return (
        <Dimmer inverted={inverted} active={true}>
            <LogoLottie></LogoLottie>

        </Dimmer>
    )
}