
import { SET_ONBOARDING_NEEDED } from './onboardingConstants';
  
  const initialState = {
    onboardingNeeded: false,
  };
  
  export default function profileReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
      case SET_ONBOARDING_NEEDED:
        return {
          ...state,
          onboardingNeeded: payload,
        };

      default: {
        return state;
      }
    }
  }
  