import React from 'react';
import { Segment, Popup, Button, Grid, Confirm } from 'semantic-ui-react';
import { format } from 'date-fns';
import { noteBodyJSX } from '../../app/common/util/util';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from '../../app/common/modals/modalReducer';
import { useEffect } from 'react';
import { useState } from 'react';
import firebase from '../../app/config/firebase';
import NoteImageAttachment from './NoteImageAttachment';
import { deleteNoteInFirestore, updateExcludeInFirestore, incrementNoteCount } from '../../app/firestore/firestoreService';
import { toast } from 'react-toastify';
import { logEvent } from '../../app/common/logging/logging';

export default function NoteListItem({ note, isHowTo, excluded, isExcluded, topicId }) {

  const dispatch = useDispatch();

  async function handleClick() {
    logEvent("Did Select Edit Note")
    if (selectedTopic.shared_id) {
      toast.info("This note was shared with you, so it can't be edited.")
    } else {
      dispatch(openModal({ modalType: 'EditorModal', modalProps: { note: note, topicId: topicId, excluded: excluded, isExcluded: isExcluded } }))

    }
  }

  const { selectedTopic } = useSelector((state) => state.topic);


  const [confirmOpen, setConfirmOpen] = useState(false);

  const [popupOpen, setPopupOpen] = useState(false);

  function handleOpen() {
    setPopupOpen(true);
    logEvent("Note Details Tapped")

  }

  function handleClose() {
    setPopupOpen(false);
  }

  async function handleDelete() {
    setPopupOpen(false);
    setConfirmOpen(true)
    logEvent("Delete Note Tapped")

  }

  async function deleteConfirmed(event) {


    event.preventDefault();
    event.stopPropagation();

    deleteNoteInFirestore(note.id)
    incrementNoteCount(selectedTopic.id, -1);

    //setConfirmOpen(false)
    dispatch(closeModal());
    logEvent("Delete Note Confirmed")

  }

  async function toggleExclude() {
    setPopupOpen(false);

    const isCurrentlyExcluded = excluded.includes(note.id);

    if (isCurrentlyExcluded) {
      logEvent("Tapped Include Note")

    } else {
      logEvent("Tapped Exclude Note")

    }

    toast.done();
    toast.info(
      isCurrentlyExcluded
        ? "Note will be included in reviews."
        : "Note will be excluded from reviews."
    );

    updateExcludeInFirestore(!isCurrentlyExcluded, note, topicId);
  }


  async function popupClicked(event) {
    setPopupOpen(false);

    event.preventDefault();
    event.stopPropagation();

  }


  async function popupButtonClicked(event) {
    setPopupOpen(!popupOpen);

    event.preventDefault();
    event.stopPropagation();

  }


  return (
    <div className="noteListItem" key={note.id} onClick={handleClick}>
      <Segment.Group style={{ boxShadow: "0 4px 4px 0 rgba(0,0,0,0.03)", borderRadius: 10,  marginTop: 8, marginBottom: 8 }}>

        <Segment style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, fontSize: '11px', color: '#B3B3B3', display: "flex", justifyContent: "space-between" }} className="titleTextFont">
          <div>
            {format(note.created_at.toDate(), 'MMMM d, yyyy · h:mm a')}
          </div>

          <div style={{ display: "flex", height: 19 }}>
            {isExcluded &&
              <div style={{ height: 19, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <img src='/assets/excluded.png' alt='logo' style={{ width: "auto", height: 14 }} />

              </div>
            }

            <div style={{ height: 19 }}>
              <Popup
                 position='right center'
                wide
                open={popupOpen}
                onClose={handleClose}
                onOpen={handleOpen}
                onClick={popupClicked}

                trigger={
                  <Button
                    onClick={popupButtonClicked}

                    style={{
                      backgroundColor: "#fff",
                      paddingLeft: 12,
                      paddingRight: 0,
                      height: 19,
                      display: "flex",
                      flexDirection: "column",

                      justifyContent: "center",
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                  >
                    <img
                      src='/assets/dots.png'
                      style={{
                        height: 4,
                        width: "auto",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Button>
                }
                on='click'
              >
                <Grid divided columns='equal'>
                  <Grid.Column style={{ padding: 0 }}>
                    <Button
                      onClick={toggleExclude}
                      style={{
                        color: "#3F3F3F",
                        backgroundColor: "#FFF",
                        textAlign: "center",
                        width: "100%",
                        lineHeight: 1.1,
                      }}
                      className=''
                    >
                      {isExcluded ? "Include in review" : "Exclude from review"}
                    </Button>
                  </Grid.Column>
                  { !selectedTopic.shared_id &&
                  <Grid.Column
                    style={{ padding: 0, display: "flex", alignItems: "center" }}
                  >
                    <Button
                      onClick={handleDelete}
                      style={{
                        color: "#3F3F3F",
                        backgroundColor: "#FFF",
                        textAlign: "center",
                        width: "100%",
                        lineHeight: 1.1,
                      }}
                      className=''
                    >
                      Delete note
                  </Button>
                  </Grid.Column>}
                </Grid>
              </Popup>

              <Confirm
                header='Delete note'

                content={
                  "Are you sure you want to delete this note? This cannot be undone."
                }
                confirmButton={"Delete note"}
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                onConfirm={deleteConfirmed}
              />
            </div>
          </div>


        </Segment>
        {note.question && (
          <Segment clearing className="questionSegment" >
            <span className="titleTextFont">Question: </span><span className="normalTextFont" style={{ color: "#6D6D6D"}} className="questionText">{note.question}</span>

          </Segment>
        )}
        <Segment clearing style={{ paddingTop: '0px', borderTop: "none", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
          {noteBodyJSX(note, "fitdm", true, isHowTo)}


        </Segment>

        {note.image_attachment_full_path && (
          <Segment clearing style={{ paddingTop: '0px', borderTop: "none", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
            <NoteImageAttachment note={note} ></NoteImageAttachment>


          </Segment>
        )}

      </Segment.Group>
    </div>
  );
}
