import React from 'react';

import { TextArea, Grid, Button, Icon, Container, Popup, Dimmer, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor, Node } from 'slate'
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { withHistory } from 'slate-history'
import isHotkey from 'is-hotkey'
import { buildNote, addNoteToFirestore, updateNoteInFirestore, incrementNoteCount, incrementTextScanCount } from '../../app/firestore/firestoreService';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { noteBodyJSX, noteBodyForSlate } from '../../app/common/util/util';
import { useDropzone } from 'react-dropzone'
import ImageEditor from './ImageEditor';
import { useEffect } from 'react';
import firebase, { remoteConfig } from '../../app/config/firebase'
import { toast } from 'react-toastify';
import { deleteFromFirebaseStorage } from '../../app/firestore/firebaseService';
import { Point } from 'slate'
import { logEvent } from '../../app/common/logging/logging';
import { incrementTextScanCountLocally } from '../auth/authActions';
import {Detector} from "react-detect-offline";

const HOTKEYS = {
  // 'mod+b': 'bold',
  // 'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+/': 'question',
  'shift+enter': 'save',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']


export default function NoteEditor({ editingNote }) {

  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [uploadedImage, setUploadedImage] = useState((editingNote && editingNote.image_attachment_bucket) && { bucket: editingNote.image_attachment_bucket, fullPath: editingNote.image_attachment_full_path, filename: editingNote.image_attachment_filename });
  const [uploadedImageDimensions, setUploadedImageDimensions] = useState(null);
  const [imageDownloadURL, setImageDownloadURL] = useState(null);
  const { currentUserProfile } = useSelector((state) => state.profile);

  const { isSubscriber } = useSelector((state) => state.auth);
  const { selectedTopic, userTotalNoteCount } = useSelector((state) => state.topic);
  const { sidebarVisible } = useSelector(state => state.sidebar);

  const questionSerialize = nodes => {
    return nodes.map(n => Node.string(n)).join('\n')
  }

  const serialize = nodes => {

    let finalString = ""
    let manualImportantRanges = []
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i]
      let string = Node.string(node)

      if (node.type == "paragraph" && i > 0) {
        string = "\n" + string
      }

      let startIndex = finalString.length
      finalString = finalString + string

      var childOffset = 0
      for (let k = 0; k < node.children.length; k++) {
        let child = node.children[k]

        let textLength = (child.text ? child.text.length : 0)
        if (child.underline === true) {
          manualImportantRanges.push({
            length: textLength,
            location: startIndex + childOffset
          })
        }
        childOffset = childOffset + textLength


      }
    }

    return { body: finalString, manualImportantRanges }

  }

  // If you only want to run the function given to useEffect after the initial render, you can give it an empty array as second argument.
  useEffect(() => {
    const start = {
      path: [0, 0],
      offset: 0,
    }

    Transforms.select(editor, start);
  }, []);

  useEffect(() => {

    if (uploadedImage && uploadedImage.fullPath) {
      const storageRef = firebase.storage().ref();
      storageRef.child(uploadedImage.fullPath).getDownloadURL().then(downloadURL => {
        setImageDownloadURL(downloadURL);
      })
    }

    return () => { }
  }, [uploadedImage]);

  var initialQuestionValue;
  var initialBodyValue;

  if (editingNote) {

    if (editingNote.question) {


      initialQuestionValue = [{
        type: 'paragraph',
        children: [{ text: editingNote.question }],
      }]

    } else {
      initialQuestionValue = initialEmptyValue;
    }

    initialBodyValue = noteBodyForSlate(editingNote)

  } else {
    initialQuestionValue = initialEmptyValue;
    initialBodyValue = initialEmptyValue;
  }


  const [showQuestion, setShowQuestion] = useState(editingNote && editingNote.question_type == "question" && editingNote.question)
  const [imageURL, setImageURL] = useState(null)

  const [ocrLoading, setOcrLoading] = useState(false);
  const [imageAttachmentLoading, setImageAttachmentLoading] = useState(false);

  const [imagePopupOpen, setImagePopupOpen] = useState(false);

    function handleImagePopupOpen() {
      setImagePopupOpen(true);
    }
  
    function handleImagePopupClose() {
      setImagePopupOpen(false);
    }

  const [questionValue, setQuestionValue] = useState(initialQuestionValue)
  const questionEditor = useMemo(() => withHistory(withReact(createEditor())), [])

  const [value, setValue] = useState(initialBodyValue)
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const imgElement = React.useRef(null);


  function stopTextScanIfNecessary() {
    if (!editingNote &&!isSubscriber && currentUserProfile && currentUserProfile.text_scan_count && currentUserProfile.text_scan_count >= remoteConfig().getValue('textScanLimit').asNumber()) {

      dispatch(closeModal);
      dispatch(openModal({
        modalType: 'SubscribeWall', modalProps: {
          subWall: true,
          title: "Upgrade to Notefuel Premium",
          subTitle: "You've reached your text scan limit (" + remoteConfig().getValue('textScanLimit').asNumber() + ")."
        }
      }))

      logEvent("Sub Wall Hit Note Limit")
      return true;
    } else {
      return false;
    }
  }


  async function ocrExistingImage() {

    setImagePopupOpen(false);

    if (stopTextScanIfNecessary()) {
      return;
    }    

    ocrImage(uploadedImage.filename, false)
    logEvent("Editor OCR Existing Image Tapped")
  }

  async function ocrNewImage(filename) {
    ocrImage(filename, true)
    logEvent("Editor OCR Image Tapped")

  }

  async function ocrImage(filename, deleteAfter) {

    setOcrLoading(true);

    const functionRef = firebase.app().functions("us-central1").httpsCallable('ocrScanText');
    const { data } = await functionRef({ filename: filename });

    setOcrLoading(false);

    var ocrText = data.replace("-\n ", "").replace("-\n", "").replace("\n", " ").replace("- ", "");

    if (ocrText && ocrText.match(/^\s+$/) === null) {

      Transforms.insertNodes(editor,
        [{
          text: ocrText,
        }]
      )

      incrementTextScanCount();
    } else {
      toast.info("No text was found in the image.")
    }

    if (deleteAfter) {
      deleteFromFirebaseStorage(filename)
    }


  }

  async function removeUploadedImage() {
    deleteFromFirebaseStorage(uploadedImage.filename)

    setUploadedImage(null)
    setUploadedImageDimensions(null)
    logEvent("Editor Remove Image Tapped")

  }

  function getEndPath(editorToGetPathFor) {

    const start = {
      path: [0, 0],
      offset: 0,
    }
 
    var currentNodeOffset = 0

    let path = []
    let currentNode = editorToGetPathFor

    while (currentNode.children && currentNode.children.length > 0) {

      path.push(currentNode.children.length - 1)
      currentNode = currentNode.children[currentNode.children.length - 1]

    }

    // if (!currentNode.children || currentNode.children.length <= 0) {
    //   path.p
    // }

    if (path.length == 0) {
      path = [0,0]
    }
    if (currentNode.text) {
      currentNodeOffset = currentNode.text.length - 2
    }

    const point = {
      path: path,
      offset: currentNodeOffset
    }

    return point
  }

  const isEmpty = (value.map(n => Node.string(n)).join('').length == 0) && !uploadedImage

  async function questionTapped() {
    logEvent("Editor Question Button Tapped")

    setShowQuestion(!showQuestion);
   
    const start = {
      path: [0, 0],
      offset: 0,
    }
 

    if (!showQuestion) {

      Transforms.select(questionEditor, start);

      setTimeout(async function () {

        const editorEl = document.querySelector('#questionEditableSlate');
        editorEl.focus();
      }, 100);

    } else {

      // var endPath = getEndPath(editor)
      // console.log("End path calculated: " + JSON.stringify(endPath))
      // //Transforms.select(editor, endPath);

      // const testEnd = {
      //   path: [0, 0],
      //   offset: 2,
      // }
   
      Transforms.select(editor, start);


      setTimeout(async function () {

        const editorEl = document.querySelector('#bodyEditableSlate');
        editorEl.focus();
      }, 100);

    }


  }

  async function saveTapped() {

    // For the keyboard case, don't submit if we're empty
    if (isEmpty) {
      return;
    }

    if (!editingNote && !isSubscriber && userTotalNoteCount >= remoteConfig().getValue('noteLimit').asNumber()) {

      dispatch(closeModal);
      
      dispatch(openModal({ modalType: 'SubscribeWall', modalProps: { subWall: true,
        subTitle: "You've reached your plan's note limit (" + remoteConfig().getValue('noteLimit').asNumber() + ")." } }))

        logEvent( "Sub Wall Hit Note Limit")
      return;
    }

    setSubmitting(true)
    const { body, manualImportantRanges } = serialize(value)
    const questionText = showQuestion ? questionSerialize(questionValue) : null
    const note = buildNote(editingNote, selectedTopic, body, questionText, manualImportantRanges, uploadedImage, uploadedImageDimensions, isSubscriber)


    if (!editingNote) {
      logEvent("Created Note")

      addNoteToFirestore(note);
      incrementNoteCount(selectedTopic.id, 1);
      Transforms.deselect(editor)
      Transforms.deselect(questionEditor)

      setShowQuestion(false);

      setUploadedImage(null)
      setUploadedImageDimensions(null)
      setValue(initialEmptyValue)
      setQuestionValue(initialEmptyValue)
      setSubmitting(false);

      const start = {
        path: [0, 0],
        offset: 0,
      }

      Transforms.select(editor, start);
    } else {
      logEvent("Updated Note")

      updateNoteInFirestore(note);
      dispatch(closeModal());
      setSubmitting(false);
    }
  }


  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })))
  }, [setFiles])
  const { fileRejections, getRootProps, getInputProps, open, isDragActive } = useDropzone({ onDrop, noClick: true, accept: 'image/jpeg, image/png' })

  const dropzoneStyles = {
    // border: 'dashed 3px #eee',
    // borderRadius: '5%',
    //textAlign: 'center'
  }

  const dropzoneActive = {
    border: 'dashed 1px #EF4136'
  }

  function handleOfflineMessage() {
    toast.info("Attaching images and scanning text is not supported in offline mode on web. Otherwise, Notefuel will work normally offline.")
    logEvent("Image Offline Message")

  }

  return (
    <div
      className='dz'
      {...getRootProps()}
      style={
        isDragActive
          ? { ...dropzoneStyles, ...dropzoneActive }
          : dropzoneStyles
      }
    >
      <input {...getInputProps()} />

      <Grid
        verticalAlign='middle'
        columns={1}
        centered
        style={{
          backgroundColor: "#FFF",
          fontSize: 15,
          margin: 0,
          boxShadow: "0 4px 20px 0 rgba(0,0,0,0.1)",
          borderRadius: 10,
        }}
        className=''
      >

        {files.length > 0 ? (

          <ImageEditor
            files={files}
            setFiles={setFiles}
            setUploadedImage={setUploadedImage}
            setUploadedImageDimensions={setUploadedImageDimensions}
            setImageDownloadURL={setImageDownloadURL}
            ocrNewImage={ocrNewImage}
            setOcrLoading={setOcrLoading}
            setImageAttachmentLoading={setImageAttachmentLoading}
            stopTextScanIfNecessary={stopTextScanIfNecessary}
            handleOfflineMessage={handleOfflineMessage}
          ></ImageEditor>

        ) :
          (
            <>


              {showQuestion && (
                <Grid.Row centered style={{ paddingTop: 20, paddingBottom: 0 }}>
                  <Grid.Column style={{}}>
                    <div
                      style={{
                        background: "#FAFAFA",
                        borderRadius: "20px",
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                        fontSize: 13,
                        border: "1px solid #F1F1F1"
                      }}
                    >
                      <Slate
                        id="questionEditorSlate"
                        editor={questionEditor}
                        value={questionValue}
                        onChange={(value) => setQuestionValue(value)}
                      >
                        <div style={{ maxHeight: "12vh", overflowY: "scroll" }}>
                          <Editable id="questionEditableSlate" placeholder='Add a question or key phrase'
                                                  renderElement={renderElement}
                                                  renderLeaf={renderLeaf}
                          onKeyDown={(event) => {
                            for (const hotkey in HOTKEYS) {
                              if (isHotkey(hotkey, event)) {
                                event.preventDefault();
  
                                if (HOTKEYS[hotkey] == "save") {
                                  saveTapped();
                                  return;
                                }
  
  
                                if (HOTKEYS[hotkey] == "question") {
                                  questionTapped();
                                  return;
                                }
  

                              }
                            }
                          }}
                          />
                        </div>
                      </Slate>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )}

              <Slate
                editor={editor}
                autoFocus
                value={value}
                onChange={(value) => setValue(value)}
              >
                <Grid.Row centered style={{ }}>
                  <Grid.Column style={{}}>
                    <div style={{ maxHeight: "40vh", overflowY: "scroll" }}>


                      <Editable
                      id="bodyEditableSlate"
                        style={{ fontSize: 14 }}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder= 'Type a new note...'
                        spellCheck
                        autoFocus
                        // onKeyDown={onKeyDown}
                        onKeyDown={(event) => {
                          for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                              event.preventDefault();

                              if (HOTKEYS[hotkey] == "save") {
                                saveTapped();
                                return;
                              }


                              if (HOTKEYS[hotkey] == "question") {
                                questionTapped();
                                return;
                              }

                              const mark = HOTKEYS[hotkey];
                              toggleMark(editor, mark);
                            }
                          }
                        }}
                      />



                    </div>
                    {ocrLoading &&
                    <div style={{backgroundColor: "#fff"}}>
                      <Loader style={{backgroundColor: "#fff"}} className="editorOCRLoader" active inline size={"mini"} style={{marginTop: 10}} />
                      </div>
                    }
                  </Grid.Column>
                </Grid.Row>


                


                {(imageAttachmentLoading || uploadedImage) && (
                  <Grid.Row centered style={{ marginTop: 0, marginBottom: 0, borderTop: "1px solid #F1F1F1"  }}>
                    <Grid.Column width={16} style={{height: 80}}>

                    {((imageAttachmentLoading && !(uploadedImage && imageDownloadURL)) || (uploadedImage && !imageDownloadURL)) && (
  
                      <div style={{ width: 80, height: 80, display:"flex", flexDirection:"column", justifyContent:"center" }}>
                          <Loader active inline size="large" style={{}} />
                      </div>
     
                )}

                { (uploadedImage && imageDownloadURL) &&
                

                      <div>
                        <Popup
                          open={imagePopupOpen}
                          onClose={handleImagePopupClose}
                          onOpen={handleImagePopupOpen}
                          wide
                          trigger={
                            <img
                              src={imageDownloadURL}
                              style={{ width: "auto", height: 80 }}
                              ref={imgElement}
                              onLoad={() =>
                                setUploadedImageDimensions({
                                  height: imgElement.current.naturalHeight,
                                  width: imgElement.current.naturalWidth,
                                })
                              } // print 150
                            />
                          }
                          on='click'
                        >
                          <Grid divided columns='equal'>
                            <Grid.Column
                              style={{
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Detector
                                render={({ online }) => (
                                  <Button
                                    onClick={online ? ocrExistingImage : handleOfflineMessage}
                                    style={{
                                      color: "#3F3F3F",
                                      backgroundColor: "#FFF",
                                      textAlign: "center",
                                      width: "100%",
                                      lineHeight: 1.1,
                                    }}
                                    className=''
                                  >
                                    Scan text
                                  </Button>
                                )} />
                            </Grid.Column>
                            <Grid.Column
                              style={{
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                onClick={removeUploadedImage}
                                style={{
                                  color: "#3F3F3F",
                                  backgroundColor: "#FFF",
                                  textAlign: "center",
                                  width: "100%",
                                  lineHeight: 1.1,
                                }}
                                className=''
                              >
                                Remove image
                          </Button>
                            </Grid.Column>
                          </Grid>
                        </Popup>
                      </div>
}
                    </Grid.Column>
                  </Grid.Row>
                )}

                <Grid.Row centered style={{display: "flex", justifyContent: "space-between", borderTop: "1px solid #F1F1F1" }}>
                  <div style={{paddingLeft: 16}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {/* <Button
                        onClick={open}
                        circular
                        style={{
                          backgroundColor: "#F7F7F7",
                          height: 40,
                          width: 40,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src='/assets/ocr.png'
                          alt='logo'
                          style={{ width: "auto", height: 20 }}
                        />
                      </Button> */}

                      <Button
                        onClick={open}
                        circular
                        style={{
                          backgroundColor: "#F7F7F7",
                          height: 40,
                          width: 40,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 8,
                        }}
                      >
                        <img
                          src='/assets/image.png'
                          alt='logo'
                          style={{ width: "auto", height: 20 }}
                        />
                      </Button>
                      <Button
                        onClick={questionTapped}
                        circular
                        style={{
                          backgroundColor: "#F7F7F7",
                          height: 40,
                          width: 40,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 8,
                        }}
                      >
                        <img
                          src='/assets/question.png'
                          alt='logo'
                          style={{ width: "auto", height: 20 }}
                        />
                      </Button>

                      <MarkButton format='underline' icon='format_underlined' />
                    </div>
                  </div>
                  <div style={{paddingRight: 16}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        active={!isEmpty && !submitting}
                        loading={submitting}
                        className='editorSaveButton'
                        style={{
                          width: 80,
                          height: 40,
                          textAlign: "center",
                          borderRadius: 22,
                          padding: 0,
                          marginTop: 0,
                          marginRight: 0
                        }}
                        size='large'
                        content='Save'
                        onClick={saveTapped}
                      />
                    </div>
                  </div>{" "}
                </Grid.Row>
              </Slate>
            </>)}
        </Grid>
    </div>
  );
}



const initialEmptyValue = [

  {
    type: 'paragraph',
    children: [{ text: ' ' }],
  },
]

const initialQuestionValue = [
  {
    children: [
      { text: '' },
    ],
  },
]

const initialValue = [

  {
    type: 'paragraph',
    children: [{ text: 'Try it out for yourself!' }],
  },
]



const QuestionSlate = () => {
  const [value, setValue] = useState(initialQuestionValue)
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  return (
    <div style={{ background: "#FAFAFA", border: "1px solid #F1F1F1", borderRadius: "20px", paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8, fontSize: 13 }}>
      <Slate editor={editor} value={value} onChange={value => setValue(value)}>
        <Editable placeholder="Add a question or key phrase" />
      </Slate>
    </div>
  )
}


const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {

  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const OldMarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      className="editorButton"
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
        logEvent("Editor Mark Important Tapped")

      }}
      circular style={{ height: 40, width: 40, display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 8 }} >
      <img src='/assets/highlight.png' alt='logo' style={{ width: "auto", height: 20 }} />
    </Button>

  )
}

