import React from 'react';
import EventDashboard from '../../features/events/eventDashboard/EventDashboard';
import NavBar from '../../features/nav/NavBar';
import { Container, SidebarPusher, Sidebar, Segment } from 'semantic-ui-react';
import { Route, useLocation, Switch } from 'react-router-dom';
import HomePage from '../../features/home/HomePage';
import EventDetailedPage from '../../features/events/eventDetailed/EventDetailedPage';
import EventForm from '../../features/events/eventForm/EventForm';
import Sandbox from '../../features/sandbox/Sandbox';
import ModalManager from '../common/modals/ModalManager';
import { ToastContainer } from 'react-toastify';
import ErrorComponent from '../common/errors/ErrorComponent';
import AccountPage from '../../features/auth/AccountPage';
import { useSelector } from 'react-redux';
import LoadingComponent from './LoadingComponent';
import ProfilePage from '../../features/profiles/profilePage/ProfilePage';
import PrivateRoute from './PrivateRoute';
import HomepageLayout from '../../features/home/HomepageLayout';
import LeftSidebar from '../../features/sidebar/LeftSidebar';
import ReviewView from '../../features/review/ReviewView';
import ReviewNavBar from '../../features/review/ReviewNavBar';
import ReviewWrapper from '../../features/review/ReviewWrapper';
import EditorWrapper from '../../features/editor/EditorWrapper';
import MainNoteView from '../../features/notes/MainNoteView';
import SearchWrapper from '../../features/search/SearchWrapper';
import SearchNavBar from '../../features/search/SearchNavBar';
import SpecialPageNavBar from '../../features/home/SpecialPageNavBar';
import Privacy from '../../features/home/Privacy';
import Terms from '../../features/home/Terms';
import SigningInLoader from '../../features/nav/SigningInLoader';
import { remoteConfig } from '../config/firebase';

export default function App() {
  const { key } = useLocation();
  const { loadedRemoteConfig } = useSelector((state) => state.auth);

  const { initialized } = useSelector((state) => state.async);
  const { sidebarVisible } = useSelector(state => state.sidebar);
  const { onboardingNeeded } = useSelector(state => state.onboarding);

  if (!initialized || !loadedRemoteConfig || onboardingNeeded) return <LoadingComponent content='Loading app...' />

  return (
    <>
      <ModalManager />
      <ToastContainer position='top-right' hideProgressBar />

      <Switch>

        {/* <Route exact path='/' component={HomepageLayout} /> */}
        <Route exact path='/signing_in' component={SigningInLoader} />

        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/terms' component={Terms} />
        {/* <Route exact path='/' component={HomePage} /> */}
        <Route
          // path={'/(.+)'}
          render={() => (
            <>
              <Sidebar.Pushable>

                  <Switch>
                    <Route path='/search' component={LeftSidebar} />


                    {/* This needs to be first so that the review is in the match */}
                    <Route path='/topics/:topicId/review' component={LeftSidebar} />

                    <Route path='/topics/:topicId' component={LeftSidebar} />
                    <Route component={LeftSidebar} />
                  </Switch>
                

                <Sidebar.Pusher style={{height: "100%", minHeight: '100%' }} >
                  <Switch>
                    <Route path='/search' component={SearchNavBar} />
                    <Route path='/topics/:id/review' component={ReviewNavBar} />


                    <Route component={NavBar} />
                  </Switch>

                  <div className={sidebarVisible ? "main sidebarVisible" : "main "} style={{ height: "100%", minHeight: "100%", marginTop: 0, paddingBottom: 0 }}>
                    {/* <ReviewView></ReviewView> */}
                    <Route path='/search' component={SearchWrapper} />

                    <Route path='/topics/:id/review' component={ReviewWrapper} />

                    <Route exact path='/topics/:id' component={MainNoteView} />
                    <Route exact path='/app' component={MainNoteView} />




                    <Route exact path='/events' component={EventDashboard} />
                    <Route exact path='/sandbox' component={Sandbox} />
                    <Route path='/events/:id' component={EventDetailedPage} />
                    <PrivateRoute
                      path={['/createEvent', '/manage/:id']}
                      component={EventForm}
                      key={key}
                    />
                    <PrivateRoute path='/account' component={AccountPage} />
                    <PrivateRoute path='/profile/:id' component={ProfilePage} />
                    <Route path='/error' component={ErrorComponent} />
                  </div>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
            </>
          )}
        />
      </Switch>

    </>
  );
}
