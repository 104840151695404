
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReviewCarouselItem from './ReviewCarouselItem';

const handleDragStart = (e) => e.preventDefault();


const reviews = [
    {
        userName: "marioarroyo",
        date: "10/27/2020",
        text: "Exactly what I've been searching for. Notefuel is the first app that gives me the freedom to study longer passages. I have searched all over the App Store for something like this."
    },
    {
        userName: "marioarroyo",
        date: "10/27/2020",
        text: "Exactly what I've been searching for. g like this."
    }

]

const items = [
    <ReviewCarouselItem />,
    <ReviewCarouselItem />,
    <ReviewCarouselItem />,
    <ReviewCarouselItem />,

];


export default function ReviewCarousel() {

    return (
        <AliceCarousel id="reviewCarousel" mouseTracking items={reviews.map( review =>  <ReviewCarouselItem review={review} />)} disableButtonsControls={true} />
    );

}
