import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';

import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Image, Segment } from 'semantic-ui-react';

import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';

import firebase from '../../app/config/firebase';
import { useState } from 'react';
import { logError, logEvent } from '../../app/common/logging/logging';


export default function CheckoutForm({ subscribeSuccessful, showFreeTrial }) {
  const stripe = useStripe();
  const elements = useElements();

  const { currentUserProfile } = useSelector(state => state.profile);

  const [loading, setLoading] = useState(false);

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#EF4136',
        color: '#EF4136',
        // fontWeight: 500,
        fontFamily: 'Avenir-Medium, Helvetica',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#888',
        },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
  };

  

  const handleSubmit = async (event) => {
    logEvent("Subscribe Tapped");

    setLoading(true);

    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setLoading(false);

      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      //console.log('[createPaymentMethod error]', error);
      logError(error);

      toast.error( error.message, {
       //autoClose: 10000,


      });

      setLoading(false);
      return;


    } else {
      //console.log('[PaymentMethod]', paymentMethod);

      const paymentMethodId = paymentMethod.id;      

      try {
        // Call billing portal function
        const functionRef = firebase.app().functions("us-central1").httpsCallable('stripeCreateSubscriptionWithPaymentMethod');
        const { data } = await functionRef({ paymentMethodId: paymentMethodId, requestingFreeTrial: showFreeTrial });
  
        setLoading(false);

        subscribeSuccessful();
        logEvent("Subscribe Purchase Succeeded")
        return;

      } catch (error) {
        toast.error(error.message);
       logError(error);

        setLoading(false);
        return;


      }


    }
  };



  async function createCheckoutSession() {

    const db = firebase.firestore();
    const user = firebase.auth().currentUser;

    const docRef = await db
      .collection('users')
      .doc(user.uid)
      .collection('checkout_sessions')
      .add({
        price: "price_1HWUPFIm3ZceciUzf0XKpsVC",
        allow_promotion_codes: true,
        //tax_rates: taxRates,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        metadata: {
          tax_rate: '10% sales tax exclusive',
        },
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and then inspect your function logs.
        alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        stripe.redirectToCheckout({ sessionId });
      }
    });
  }  

  return (
    
    <form onSubmit={handleSubmit}>
      <CardElement options={CARD_OPTIONS} />
      {/* <button type="submit" disabled={!stripe}>
        Pay
      </button> */}

              <Button loading={loading} type="submit" disabled={!stripe || !elements} style={{ borderLeft: 0, backgroundColor: "#fff", height: 50, width: 200, marginTop: 40}}
                className='reviewCompletePrimaryButton normalTextFont' content= {showFreeTrial ? 'START 7-DAY TRIAL' : 'SUBSCRIBE' } />              


      {/* <Button onClick={createCheckoutSession} icon='facebook' fluid color='facebook' style={{marginBottom: 10}} content='Login with Facebook' /> */}

    </form>
  );
}

