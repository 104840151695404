import { SIGN_IN_USER, SIGN_OUT_USER, SET_SUBSCRIBER, SET_STRIPE_SUBSCRIPTIONS, SET_LOADED_REMOTE_CONFIG } from './authConstants';
import {LOCATION_CHANGE} from 'connected-react-router';

const initialState = {
  authenticated: false,
  currentUser: null,
  prevLocation: null,
  currentLocation: null,
  subscriberInfo: null,
  isSubscriber: null,
  isSubscriberThroughStripe: null,
  stripeSubscriptions: [],
  loadedRemoteConfig: false

};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LOADED_REMOTE_CONFIG:
      return {
        ...state,
        loadedRemoteConfig: payload,
      };


    case SIGN_IN_USER:
      return {
        ...state,
        authenticated: true,
        currentUser: {
          email: payload.email,
          photoURL: payload.photoURL,
          uid: payload.uid,
          displayName: payload.displayName,
          providerId: payload.providerData[0]?.providerId
        },
      };
    case SIGN_OUT_USER:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
        subscriberInfo: null,
        isSubscriberThroughStripe: null,
        stripeSubscriptions: []
      };
      case SET_SUBSCRIBER:
        return {
          ...state,
          subscriberInfo: payload,
          isSubscriber: payload.entitlements && Object.keys(payload.entitlements ).length > 0,
          isSubscriberThroughStripe: payload.subscriptions && Object.values(payload.subscriptions).length > 0 && Object.values(payload.subscriptions)[0].store == "stripe"
        };     
      case SET_STRIPE_SUBSCRIPTIONS:
        return {
          ...state,
          stripeSubscriptions: payload,

        };           
      case LOCATION_CHANGE:
        return {
          ...state,
          prevLocation: state.currentLocation,
          currentLocation: payload.location
        }
    default:
      return state;
  }
}
