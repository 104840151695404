import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Checkbox, Item, Button, Select, Grid, Progress, Container } from 'semantic-ui-react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { closeModal } from '../../app/common/modals/modalReducer';
import { setAutoflashcardsEnabled, setRandomOrder, setNumberOfNotes, setReviewType, fetchReviewingNotes, setShowCurrentAnswer, setReviewInitialState } from './reviewActions';
import { useEffect } from 'react';
import { useCallback } from 'react';
import ReviewView from './ReviewView';
import ReviewConfigureView from './ReviewConfigureView';
import ReviewCompleteView from './ReviewCompleteView';
import useFirestoreDoc from '../../app/hooks/useFirestoreDoc';
import { listenToTopicFromFirestore } from '../../app/firestore/firestoreService';
import { listenToSelectedTopic } from '../topics/topicActions';

export default function ReviewWrapper({ match }) {
    const dispatch = useDispatch();

    const { selectedTopic, selectedTopicId } = useSelector((state) => state.topic);
    const { reviewType, notes, showCurrentAnswer, stage } = useSelector((state) => state.review);
    const { sidebarVisible } = useSelector(state => state.sidebar);


    useEffect(() => {

        if (selectedTopic) {
            dispatch(setReviewInitialState());

            dispatch(fetchReviewingNotes(selectedTopic)).then(() => {                
            });
        }

        return () => {

        }
    }, [dispatch, selectedTopicId]);

    return (
        <Container className={sidebarVisible ? "sidebarVisible" : ""} style={{fontSize: 16, paddingTop: 96 }}>

            {
                {
                    'configuring': <ReviewConfigureView />,
                    'reviewing': <ReviewView selectedTopic={selectedTopic} notes={notes} />,
                    'complete': <ReviewCompleteView />
                }[stage]
            }


        </Container>

    )
}