import React from 'react';
import { Grid, Loader, Sidebar, Menu, Segment, Dropdown, Button, Popup } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import { useState } from 'react';
import { useEffect } from 'react';
import { fetchTopics, listenToSelectedTopic, clearJustRemovedTopic } from '../topics/topicActions';
import TopicList from '../topics/TopicList';
import { setFilter } from '../topics/topicActions';
import { RETAIN_STATE } from '../events/eventConstants';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { signOutFirebase } from '../../app/firestore/firebaseService';
import ChangeEmailForm from '../auth/ChangeEmailForm';
import { openModal } from '../../app/common/modals/modalReducer';
import firebase, { remoteConfig } from '../../app/config/firebase';
import SubscribeWall from '../auth/SubscribeWall';
import { closeSidebar, openSidebar } from './sidebarReducer';
import { clearNotes } from '../notes/noteActions';
import FoldersList from '../folders/FoldersList';
import TopicFilterDropdown from './TopicFilterDropdown';
import { FETCH_TOPICS } from '../topics/topicConstants';
import { fetchTopicsFromFirestore, getTopic, dataFromSnapshot, createSharedTopic } from '../../app/firestore/firestoreService';
import { useFirestoreDocs } from '../../app/hooks/useFirestoreDoc';
import EmailSolicitation from './EmailSolicitation';
import { setLoadedRemoteConfig } from '../auth/authActions';
import { logEvent } from '../../app/common/logging/logging';

export default function LeftSidebar({ match }) {
  const limit = 2;
  const dispatch = useDispatch();
  const { topics, moreTopics, selectedTopic, needsTopicFetch, justRemovedTopicId, justAddedTopicId } = useSelector((state) => state.topic);

  const { authenticated, subscriberInfo, isSubscriber, isSubscriberThroughStripe } = useSelector((state) => state.auth);
  const currentModal = useSelector((state) => state.modals);

  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loadingPortal, setLoadingPortal] = useState(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const { filter, filterFolder } = useSelector((state) => state.topic);
  const { currentUserProfile } = useSelector(state => state.profile);
  const { onboardingNeeded } = useSelector(state => state.onboarding);

  const { sidebarVisible } = useSelector(state => state.sidebar);
  const history = useHistory();

  const [popupOpen, setPopupOpen] = useState(false);
  const [showEmailSolicitation, setShowEmailSolicitation] = useState(false);
  const [showAddTip, setShowAddTip] = useState(false);


  function sortTopics(a, b)  {


    let aLastModified = a.updated_at ?? a.created_at
    let bLastModified = b.updated_at ?? b.created_at

    if (aLastModified != bLastModified) {
      return bLastModified - aLastModified;
    } else {
      return b.how_to - a.how_to
    }
  
  }
 
  topics.sort(sortTopics)
  

  function handleOpen() {
    setPopupOpen(true);

  }

  function handleClose() {
    setPopupOpen(false);
  }

  const addTopicButtonRef = React.useRef()

  function handleAddTipOpen() {
    setShowAddTip(true);

}

function handleAddTipClose() {
  setShowAddTip(false);
  logEvent("Add Tip Seen");

}


  useFirestoreDocs({
    shouldExecute: authenticated,
    query: () => fetchTopicsFromFirestore(),
    data: (topics) => handleFetchedTopics(topics),
    deps: [dispatch],
  });

  async function handleFetchedTopics(topics) {
    dispatch({ type: FETCH_TOPICS, payload: { topics: topics } })

  }

  async function setSelectedTopic(fetchedTopics, justRemovedTopicId) {
    //  return;

    // If we have a topic id, first check whether it's in our list of fetched topics.
    // In this case, we set it as the selectedTopic.
    if (match.params.topicId) {



      const found = fetchedTopics.find(topic => (topic.id === match.params.topicId || topic.shared_id === match.params.topicId));

      if (found) {


        if (selectedTopic && found.id !== selectedTopic.id) {
          dispatch(clearNotes());
        }

        dispatch(listenToSelectedTopic(found));

        return;
      }

      // If it's not found, then let's get the topic and see if it's shared.
      try {
        const topicSnapshot = await getTopic(match.params.topicId);
        const sharedTopic = dataFromSnapshot(topicSnapshot);

        const newlyCreatedSharedTopic = await createSharedTopic(sharedTopic);

        dispatch(clearNotes());

        dispatch(listenToSelectedTopic(newlyCreatedSharedTopic));
        logEvent("Opened Shared Topic Link")
        return;



      } catch (error) {
        console.error(error);

        toast.error("Unable to open topic. It may have been made private or deleted.");
        selectFallbackTopic(justRemovedTopicId);
      }

    } else {
      // If there's no topic in the URL, then fallback to one here.
      selectFallbackTopic(justRemovedTopicId)
    }


  }

  async function selectFallbackTopic(justRemovedTopicId) {


    var candidateTopics = justRemovedTopicId ?
      topics.filter((topic) => justRemovedTopicId != topic.id && (!topic.shared_id || topic.shared_id != justRemovedTopicId))
      : topics


    // Prioritize a topic that meets existing filter criteria if possible.
    var filteredTopics = candidateTopics.filter((topic) =>
      filter == "all" || (filter == "shared" && topic.shared_id) || (filter === "folder" && filterFolder && topic.folders && topic.folders.includes(filterFolder)
      ))

    var finalTopics

    if (filteredTopics.length > 0) {
      finalTopics = filteredTopics

    } else {
      finalTopics = candidateTopics
      dispatch(setFilter("all"))
    }

        // If one of them is the how to, and it was created recently,
        // then use that, since it's crucial for the cold start
    
    const howToTopic = topics.find(topic => (topic.how_to));

    if (howToTopic && howToTopic.created_at) {
 

      const secondsSinceHowToCreated = Math.abs((howToTopic.created_at.toDate() - new Date()) / 1000)

      if (secondsSinceHowToCreated < 2000) {
        history.push("/topics/" + howToTopic.shared_id);
        return;
      }

    }


    finalTopics = finalTopics.sort(sortTopics)

    if (finalTopics.length > 0) {
      if (topics[0].shared_id) {
        history.push("/topics/" + topics[0].shared_id);
      } else {
        history.push("/topics/" + topics[0].id);
      }
    }
  }

  useEffect(() => {
    console.log("Use effect topic fetch: " + needsTopicFetch)
    console.log("Use effect topics: " + JSON.stringify(topics))
    console.log("Use effect topicId: " + JSON.stringify(match.params.topicId))
    console.log("Use effect onboarding needed: " + JSON.stringify(onboardingNeeded))

 
    console.log("UE1")

    // If we're in search, then we don't need to worry about the fact that we don't have a selected topic.
    if (match.path == "/search") {
      return;
    }
    console.log("UE2")

    // Otherwise, if there's no topic id but there are other topics, then move to another topic
    if (!match.params.topicId && topics && topics.length > 0) {
      selectFallbackTopic()
    }

    // If we just removed a topic, then only once it's actually removed and we call here
    // should we then change the URL. But once that's done, then this will be called again
    // anyway, so return.
    if (justRemovedTopicId && match.params.topicId && (justRemovedTopicId === match.params.topicId)) {
      console.log("UE3")

      //history.push("/")
      selectFallbackTopic(justRemovedTopicId)
      dispatch(clearJustRemovedTopic());
      return;
    }
    console.log("UE4")

    // if (selectedTopic &&  match.params.topicId && (selectedTopic.id == match.params.topicId || selectedTopic.shared_id == match.params.topicId)) {
    //   setSelectedTopic(selectedTopic)
    //   return;
    //   console.log("UE4.5")

    // }

    if (!needsTopicFetch) {
      console.log("UE5")

      setSelectedTopic(topics, justRemovedTopicId, justAddedTopicId);
    }
    console.log("UE6")

    return () => {

    }
  }, [dispatch, match, topics, needsTopicFetch, justRemovedTopicId]);


  useEffect(() => {
    if (subscriberInfo && !isSubscriber && currentUserProfile && !currentUserProfile.created_on_web) {
      
      dispatch(openModal({ modalType: 'SubscribeWall', modalProps: { handleSignOut: handleSignOut, multiDeviceSubWall: true, notClosable: true,
        subTitle: "Using Notefuel across multiple devices requires Notefuel Premium." } }))

      logEvent("Sub Wall Hit Multi Device")
      return;
    }

    return () => {

    }
  }, [subscriberInfo, isSubscriber]);

  useEffect(() => {
    if (currentUserProfile && currentUserProfile.needs_welcome) {
      dispatch(openModal({ modalType: 'WelcomeModal', modalProps: {} }))
      //setShowEmailSolicitation(true);
      setShowAddTip(true);
    }

    return () => {

    }
  }, [currentUserProfile]);


  async function searchTapped() {
    if (window.innerWidth < 560) {
      dispatch(closeSidebar())
    }

    history.push("/search")
    logEvent("Search Tapped")

  }

  function handleFetchNextTopics() {
    dispatch(fetchTopics());
  }

  async function addTopic() {

    dispatch(openModal({ modalType: 'AddTopicModal' }))
    logEvent("Topic List Add Topic Tapped");

  }

  async function handleSignOut() {
    dispatch(setLoadedRemoteConfig(false));
    logEvent("Settings Logout Tapped")

    setTimeout(() => {
      try {

        signOutFirebase();
        history.push('/');

        setTimeout(() => {
          toast.info("You've been signed out and signed into a new, anonymous account.")
        }, 1300);  

      } catch (error) {
        toast.error(error.message);
      }
    }, 100);

  }

  async function toggleMenu() {
    sidebarVisible ? dispatch(closeSidebar()) : dispatch(openSidebar())

    logEvent("Toggle Menu", {sidebar_will_be_visible: !sidebarVisible})

  }

  async function billingPortal(event) {


    dispatch(openModal({ modalType: 'StripeSubscriptionModal' }));
    return;


    if (!isSubscriberThroughStripe) {
      toast.info("Since you subscribed to Notefuel Premium through Apple, you need to go to the App Store to manage your subscription.")
      return;
    }

    // Call billing portal function
    toast.info("Taking you to Notefuel's billing portal. Notefuel partners with Stripe for simplified billing.", {

      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    try {
      setLoadingPortal(true);
      const functionRef = firebase.app().functions("us-central1").httpsCallable('stripeCreateBillingPortalLink');
      const { data } = await functionRef({ returnUrl: window.location.href });
      window.location.assign(data.url);
      setLoadingPortal(false);

    } catch (error) {
      toast.error(error.message);
      setLoadingPortal(false);

    }

    logEvent("Settings Subscription Tapped", {billing_portal: true})

  }



  return (
    <Sidebar
      animation='overlay'
      icon='labeled'

      visible={sidebarVisible }
      style={{ backgroundColor: '#3F3F3F', display: 'flex', flexDirection: 'column', height: 100, overflowY: 'auto' }}

    >
      <div className="topicListFooter" style={{
        paddingBottom: 16, height: 60, display: "flex", justifyContent: "space-between"
        , backgroundColor: "", borderBottom: "0px solid #888"
      }} >



        <div style={{ display: "flex", justifyContent: "left", flexDirection: "row", height: 60 }}>



          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "100%" }} >

            <Dropdown className="settingsDropdown"
              button
              pointing='top left'
              style={{ paddingLeft: 0, paddingRight: 0, margin: 0, width: 56, height: 40 }}
              onOpen={() => { logEvent("Settings Tapped")}}
            >

              <Dropdown.Menu className="settingsDropdownMenu normalTextFont" style={{ fontSize: 14, color: "#3F3F3F", width: 232, border: 0, marginLeft: 12 }}>

                <Dropdown.Header content='Settings' />
                <Dropdown.Divider />
                {isSubscriber ?

                  <Dropdown.Item description='Notefuel Premium' text='Your plan' onClick={billingPortal} />

                  :
                  <Dropdown.Item description='Notefuel Regular' text='Your plan' onClick={() => {
                    dispatch(openModal({ modalType: 'SubscribeWall' }));
                    logEvent("Settings Subscription Tapped")
                  }
                }
                  />

                }
                <Dropdown.Item description={firebase.auth().currentUser?.email ?? 'Not set'} as={Link} to='/account'
                  text='My account' text='Your email'
                  onClick={() => {
                    dispatch(openModal({ modalType: 'ChangeEmailForm' }))
                    logEvent("Settings Change Email Tapped")
                  }

                    } />

                <Dropdown.Divider />


                <Dropdown.Item as={Link} to='/privacy' text='Privacy Policy' onClick={() => { logEvent("Settings Privacy Tapped")}} />
                <Dropdown.Item as={Link} to='/terms' text='Terms of Service'  onClick={() => { logEvent("Settings Terms Tapped")}} />
                <Dropdown.Item
                      onClick={() => {
                       logEvent("Settings Intercom Tapped")
                      }
                    }
                      style={{marginBottom: 7}}
                  text='Messsage us'
                  className="intercomButton"
                />

                <Dropdown.Divider />

                {authenticated ? (
                  <>
                  <Dropdown.Item
                      onClick={() => {
                        dispatch(openModal({ modalType: 'LoginForm' }))
                        logEvent("Settings Switch Account Tapped")
                      }
                    }
                      style={{marginBottom: 7}}
                  text='Sign into another account'
                />

                  <Dropdown.Item
                    onClick={handleSignOut}
                    style={{marginBottom: 7, color: "#EF4136"}}
                    text='Logout'
                  />
                  </>

                ) : (
                    <Dropdown.Item
                      onClick={() => dispatch(openModal({ modalType: 'LoginForm' }))}
                      text='Sign in'
                    />

                  )}

              </Dropdown.Menu>
            </Dropdown>

          </div>



          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: 46, marginLeft: -12, zIndex: 1000 }}>

            <Button onClick={() => {
              logEvent("Intercom Tapped")
            }} className="intercomButton" style={{ borderLeft: 0, backgroundColor: "rgba(0,0,0,0)", width: 30, display: "flex", justifyContent: "center" }} >
              <img src='/assets/intercom.png' alt='logo' style={{ height: 22, width: "auto" }} />
            </Button>
          </div>

          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: 46, marginLeft: -8 }}>

            <Button onClick={searchTapped} style={{ borderLeft: 0, backgroundColor: "rgba(0,0,0,0)", width: 30, display: "flex", justifyContent: "center" }} >
              <img src='/assets/search.png' alt='logo' style={{ height: 22, width: "auto" }} />
            </Button>
          </div>

        </div>

        <div style={{ display: "flex", justifyContent: "left", flexDirection: "row", height: 60 }}>


          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>

            <Button onClick={toggleMenu} style={{ borderLeft: 0, backgroundColor: "rgba(0,0,0,0)", marginRight: 0, paddingRight: 16, paddingLeft: 16 }} >
              <img src='/assets/close_menu.png' alt='logo' style={{ height: 15, width: "auto" }} />
            </Button>
          </div>
        </div>

      </div>


      <div className="topicListHeader titleTextFont" style={{ height: 60, display: "flex", justifyContent: "space-between", backgroundColor: "#3F3F3F",
    color: "#FFF", fontSize: 18, border: "none" }} >


        <div  className="titleTextFont" style={{ display: "flex", justifyContent: "center", flexDirection: "column", paddingLeft: 16, fontSize: 19 }}>
          <TopicFilterDropdown filterDropdownOpen={filterDropdownOpen} setFilterDropdownOpen={setFilterDropdownOpen} ></TopicFilterDropdown>

        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", paddingRight: 16 }}>

          <Popup style={{width: 228, marginLeft: 16, padding: 16}}
        // trigger={<Button content='Trigger Popup' />}
        open={showAddTip && !currentModal}
        onClose={handleAddTipClose}
        onOpen={handleAddTipOpen}
        context={addTopicButtonRef}
        content={
          <div style={{fontSize: 15}}>
          <span class="titleTextFont" style={{fontSize: 16}}>Tip: </span>
          take a quick look at the Notefuel How-to Guide, then tap the <img src='/assets/addTopic.png' className='bodyInlineImage' /> button
          to create your first topic and start taking notes.
          </div>
        }
        position='bottom right'
      />

            <div
              onClick={addTopic}
                  ref={addTopicButtonRef}
              style={{
                backgroundColor: "#F7F7F7",
                height: 30,
                width: 30,
                cursor: "pointer",
                borderRadius: 17,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src='/assets/addTopic.png'
                alt='logo'
                style={{ width: 15, height: 15 }}
              />
            </div>
          </div>

        </div>

      </div>

      <TopicList
        topics={topics}
        selectedTopic={selectedTopic}
        style={{ flex: "1 1 auto", height: 0, overflowY: 'auto' }}

      />
      {/* { showEmailSolicitation &&
      <div className="" style={{ paddingBottom: 16, paddingLeft: 16, paddingRight: 16, display: "flex", justifyContent: "center" }} >

        <EmailSolicitation setShowEmailSolicitation={setShowEmailSolicitation}></EmailSolicitation>
      </div>
      } */}

      { ((remoteConfig().getValue('showPremiumButtonOnWebSidebar').asBoolean()) && !isSubscriber) &&

        <div className="topicListFooter" style={{ paddingBottom: 16, paddingLeft: 16, paddingRight: 16, height: 60, display: "flex", justifyContent: "center" }} >

          <div
            style={{ display: "flex", justifyContent: "center", height: 44, padding: 0, width: "100%", backgroundColor: '#3F3F3F', visibility: true || isSubscriber === false ? "visible" : "hidden" }}
            onClick={() => {
              dispatch(openModal({ modalType: 'SubscribeWall' }));
              logEvent("Topic List Upgrade Tapped")
          }}
          >
            <div className="gradientBackground" style={{cursor: "pointer", height: 44, borderRadius: 22, display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center",
             color: "white", borderRadius: 20,
          height: 44, paddingLeft: 16, paddingRight: 16, fontSize: 14, lineHeight: "40px", width: "100%"
          
          }}>
              <div className="normalTextFont">Try <span className="titleTextFont">Notefuel Premium</span></div>

            </div>
          </div>

        </div>
      }





    </Sidebar>
  );
}
