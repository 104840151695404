import React from 'react';

import { useEffect } from 'react';
import { useState } from 'react';
import firebase from '../../app/config/firebase';

export default function NoteImageAttachment({ note }) {


  const [imageDownloadURL, setImageDownloadURL] = useState(null);

  useEffect(() => {

    if (note.image_attachment_full_path) {
      const storageRef = firebase.storage().ref();
      storageRef.child(note.image_attachment_full_path).getDownloadURL().then(downloadURL => {
        setImageDownloadURL(downloadURL);
      })
    }

    return () => {}
  }, [note]);



  return (

        <img src={imageDownloadURL} style={{width: note.image_attachment_width, maxWidth: "100%", height: "auto"}}>
        
        </img>

  );
}
