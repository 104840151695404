import { SIGN_IN_USER, SIGN_OUT_USER, SET_SUBSCRIBER, SET_STRIPE_SUBSCRIPTIONS, SET_JUST_CREATED_ANONYMOUS_ACCOUNT, SET_LOADED_REMOTE_CONFIG } from './authConstants';
import {APP_LOADED} from '../../app/async/asyncReducer';
import firebase from '../../app/config/firebase';
import { getUserProfile, dataFromSnapshot, getUserStripeSubscriptions } from '../../app/firestore/firestoreService';
import { listenToCurrentUserProfile } from '../profiles/profileActions';
import { fetchSubscriberStatusFromRevenueCat } from '../../revenueCat/revenueCatService';
import { clearStateAfterUserChanged, getSearchKeys } from '../../app/common/util/util';
import { registerInFirebaseAnonyously } from '../../app/firestore/firebaseService';
import { setAmplitudeUserId, setAmplitudeUserProperties } from '../../app/common/logging/amplitude';
import { remoteConfig } from 'firebase';
import { setOnboardingNeeded } from '../onboarding/onboardingActions';

export function signInUser(user) {
  return {
      type: SIGN_IN_USER,
      payload: user
  }
}

export function setSubscriber(value) {
  return {
      type: SET_SUBSCRIBER,
      payload: value
  }
}

export function setStripeSubscrtions(value) {
  return {
      type: SET_STRIPE_SUBSCRIPTIONS,
      payload: value
  }
}

export function setLoadedRemoteConfig(value) {
  return {
      type: SET_LOADED_REMOTE_CONFIG,
      payload: value
  }
}

export function verifyAuth() {
    return function (dispatch) {
        return firebase.auth().onAuthStateChanged(user => {
            if (user) {

              clearStateAfterUserChanged(dispatch);


              setAmplitudeUserId(user.uid);
              setAmplitudeUserProperties({
                email: user.email,
                email_verified: user.emailVerified
              })


              remoteConfig().fetchAndActivate()
              .then(() => {
                dispatch(setLoadedRemoteConfig(true));
              })
              .catch((err) => {
                  console.log(`[🆘] Error in Paradise ! `);
                  console.error(err);
                  dispatch(setLoadedRemoteConfig(true));
              
              });
              
              clearStateAfterUserChanged(dispatch);


                dispatch(signInUser(user));
                const profileRef = getUserProfile(user.uid);
                profileRef.onSnapshot(snapshot => {
                  dispatch(listenToCurrentUserProfile(dataFromSnapshot(snapshot)));
                  dispatch({type: APP_LOADED})
                })

                fetchSubscriberStatusFromRevenueCat(user.uid, dispatch);

                fetchStripeSubscriptions(user.uid, dispatch)


                getSearchKeys(dispatch);
            } else {
              console.log("Dispatching onboarding needed true")
              dispatch(setOnboardingNeeded(true));

              // If we don't have a user, then sign in an anonymous user
              registerInFirebaseAnonyously(dispatch);

            }
        })
    }
}

export async function fetchStripeSubscriptions(uid, dispatch) {
    try {
      const snapshot = await getUserStripeSubscriptions(uid).get();
      const stripeSubscriptions = snapshot.docs.map((doc) => dataFromSnapshot(doc));
      dispatch({ type: SET_STRIPE_SUBSCRIPTIONS, payload:  stripeSubscriptions  });
      //dispatch(asyncActionFinish());
    } catch (error) {
     // dispatch(asyncActionError(error));
    }

}

export function signOutUser() {
  return {
    type: SIGN_OUT_USER,
  };
}
