import firebase from '../../config/firebase'
import { format } from 'date-fns';
import React from 'react';
import { setSubscriber } from '../../../features/auth/authActions';
import { fetchTopics, listenToSelectedTopic, clearSelectedTopic } from '../../../features/topics/topicActions';
import { SET_REVIEW_INITIAL_STATE } from '../../../features/review/reviewConstants';
import { setReviewInitialState } from '../../../features/review/reviewActions';
import { FETCH_TOPICS, CLEAR_TOPICS } from '../../../features/topics/topicConstants';
import { SET_NOTES, CLEAR_NOTES } from '../../../features/notes/noteConstants';
import { RESET_STORE } from '../../store/rootConstants';
import { setSearchKeys } from '../../../features/search/searchActions';

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFileExtension(filename) {
  return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
}

export function createDataTree(dataset) {
  let hashtable = Object.create(null);
  dataset.forEach(a => hashtable[a.id] = { ...a, childNodes: [] });
  let dataTree = [];
  dataset.forEach(a => {
    if (a.parentId) hashtable[a.parentId].childNodes.push(hashtable[a.id]);
    else dataTree.push(hashtable[a.id]);
  });
  return dataTree;
}


export function noteBodyForSlate(note) {
  const sections = getNoteSectionsForFormatting(note, true, false)

  return getNoteForSlateFromSections(sections)
}

export function noteBodyJSX(note, spanClass, manualRangesOnly, howTo) {
  const sections = getNoteSectionsForFormatting(note, manualRangesOnly )

  return getNoteJSXFromSections(sections, spanClass, howTo)
}

export function getNoteForSlateFromSections(sections) {
  const children = sections.map((section) => {

    return { text: section.text, underline: section.important }
    
   })

  return [{
    type: 'paragraph',
    children: children,
  }]
}


export function getNoteJSXFromSections(sections, spanClass, howTo) {
  return sections.map((section, index) =>   {

    const textJSX = howTo ? getIconJSX(section.text) : section.text
    if (section.newLine) {
      return <span key={index}><br/></span>
    }
    if (section.important) {
      return <span key={index} className={spanClass}>{textJSX}</span>

    } else {
      return <span key={index}>{textJSX}</span>

    }
   } )
}


function textToNewlineJSX(text) {
  return text.split('\n').map(function(item, key) {
    return (
      <p>
        {item}
      </p>
    )
  })
}

function getIconJSX(text) {

  let addSplit = text.split("<<<addIcon>>>")
  if (addSplit.length == 2) {
    return [addSplit[0], <img src='/assets/addTopic.png' className='bodyInlineImage' />, addSplit[1]]
  }

  let notefuelSplit = text.split("<<<notefuelIcon>>>")
  if (notefuelSplit.length == 2) {
    return [notefuelSplit[0], <img src='/assets/logo.png' className='bodyInlineImage' />, notefuelSplit[1]]
  }

  let ocrSplit = text.split("<<<ocrIcon>>>")
  if (ocrSplit.length == 2) {
    return [ocrSplit[0], <img src='/assets/image.png' className='bodyInlineImage' />, ocrSplit[1]]
  }

  return text
}

export function getNoteSectionsForFormatting(note, manualRangesOnly = false) {

  const body = note.body /// for testing + note.body + note.body

  if (!body) {
    return [{text: "", important: false}]
  }

  var sections = []
  var currentSectionText = ""
  var currentSectionIsImportant = false
  

  var ranges = effectiveRangesForNote(note, manualRangesOnly)


  for (let i = 0; i < body.length; i++) {

    if (body[i] == "\n") {
      sections.push({important: currentSectionIsImportant, text: currentSectionText})

      let isImportant = isIndexInRanges(i, ranges)
      sections.push({important: isImportant, text: "\n", newLine: true})
      currentSectionText = ""
      currentSectionIsImportant = isImportant
      continue;
    }

    if (isIndexInRanges(i, ranges)) {

      if (currentSectionIsImportant) {
        currentSectionText = currentSectionText + body[i]
      } else {
        sections.push({important: false, text: currentSectionText})
        currentSectionText = body[i]
        currentSectionIsImportant = true
      }

    } else {
      if (currentSectionIsImportant) {
        sections.push({important: true, text: currentSectionText})
        currentSectionText = body[i]
        currentSectionIsImportant = false
      } else {

        currentSectionText = currentSectionText + body[i]
      }
    }

  }

  sections.push({important: currentSectionIsImportant, text: currentSectionText})

  return  sections;

}


export function noteBodyWithUnderlineSpans(note, spanClass, manualRangesOnly = false, howTo = false) {

  const body = note.body /// for testing + note.body + note.body

  if (!body) {
    return ""
  }

  var finalString = ""

  var isItalicsSpanOpen = false
  var italicsSpanOpen = "<span class='" + spanClass + "'>"
  var italicsSpanClose = "</span>"

  // var italicsSpanOpen = "&lt;span class='bti'&gt;"
  // var italicsSpanClose = "&lt;/span&gt;"


  var ranges = effectiveRangesForNote(note, manualRangesOnly)


  for (let i = 0; i < body.length; i++) {

    if (isIndexInRanges(i, ranges)) {

      if (isItalicsSpanOpen) {
        finalString = finalString + body[i]
      } else {
        finalString = finalString + italicsSpanOpen + body[i]

        isItalicsSpanOpen = true
      }

    } else {
      if (isItalicsSpanOpen) {

        finalString = finalString + italicsSpanClose + body[i]
        isItalicsSpanOpen = false
      } else {

        finalString = finalString + body[i]
      }
    }

  }

  if (isItalicsSpanOpen) {
    finalString = finalString + italicsSpanClose
  }

  if (howTo) {
    return replaceHowToWithImageSpans(finalString)
  }

  return finalString;

}

export function replaceHowToWithImageSpans(html) {

  var replaced = html.replace("<<<addIcon>>>", "<span> <img src='/assets/addTopic.png' class='bodyInlineImage' /></span>")
  replaced = replaced.replace("<<<notefuelIcon>>>", "<span> <img src='/assets/logo.png' class='bodyInlineImage' /></span>")
  replaced = replaced.replace("<<<ocrIcon>>>", "<span> <img src='/assets/image.png' class='bodyInlineImage' /></span>")


  return replaced;

}

function isIndexInRanges(index, ranges) {

  for (let i = 0; i < ranges.length; i++) {
    let range = ranges[i]


    if (index >= range.location && index < (range.location + range.length)) {
      return true;
    }
  }

  return false;
}

function effectiveRangesForNote(note, manualRangesOnly = false) {

  if (note.manual_important_ranges && note.manual_important_ranges.length > 0) {
    return note.manual_important_ranges
  }

  if (manualRangesOnly) {
    return [];
  }

  if (note.special_important_ranges && note.special_important_ranges.length > 0) {
    return note.special_important_ranges
  }

  if (note.google_salient_phrase) {
    let gspRanges = ranges(note.body, note.google_salient_phrase)
    return gspRanges

  }

  return []

}

function ranges(source, find) {
  if (!source || !find) {
    return [];
  }

  const findLength = find.length

  var result = [];
  for (let i = 0; i < source.length; ++i) {
    // If you want to search case insensitive use 
    // if (source.substring(i, i + find.length).toLowerCase() == find) {
    if (source.substring(i, i + find.length) == find) {
      result.push({ location: i, length: findLength });
    }
  }
  return result;
}

function compareReviews(a, b) {
  return a.completed_at - b.completed_at;
}


export function overdueCount(topic) {
  
  var noteCount = topic.note_count
  
  if (typeof noteCount === 'undefined' || noteCount == 0) {
    return 0
  }
  
  var reviewHistory = topic.review_history

  if (!reviewHistory) {
    reviewHistory = {}
  }

  var overdueInHistoryCount = 0
  var notOverdueInHistoryCount = 0
  var excludedInHistoryCount = 0

  var excludedNotesCount = 0

  var excludedNotes = topic.excluded_notes
  
  if (excludedNotes) {
    excludedNotesCount = excludedNotes.length
  } else {
    excludedNotes = []
  }
  
  for (var noteID in reviewHistory) {

    if (excludedNotes.includes(noteID)) {
      excludedInHistoryCount = excludedInHistoryCount + 1
    }
      
    if (isNoteOverdue(reviewHistory[noteID])) {
      overdueInHistoryCount = overdueInHistoryCount + 1
    } else {
      notOverdueInHistoryCount = notOverdueInHistoryCount + 1
    }

  }
  
  var excludedNotInHistory = excludedNotesCount - excludedInHistoryCount

  var totalNotInHistory = noteCount - Object.keys(reviewHistory).length
  var totalOverdueNotInHistory = totalNotInHistory - excludedNotInHistory
  var totalOverdueNotes = overdueInHistoryCount + totalOverdueNotInHistory
  
  return totalOverdueNotes
}

export function overdueCountConsideringSpacedRepetitionSetting(topic) {
  if (topic.auto_spaced_repetition != true) {
    return 0;
  }
  return overdueCount(topic);
}

export function isNoteOverdue(reviewHistory) {
  if (!reviewHistory || reviewHistory.length < 1) {
    return true
  }

  var sortedHistory = reviewHistory.sort(compareReviews)

  var lastReview = sortedHistory[reviewHistory.length - 1]

  if (lastReview.sm2_interval == 0) {
    return true
  }

  const lastReviewTime = lastReview.completed_at

  if (lastReviewTime) {

    var nextReviewDate = new Date(lastReviewTime)
    nextReviewDate.setDate(nextReviewDate.getDate() + lastReview.sm2_interval)

    const currentDate = new Date()
    return currentDate > nextReviewDate
  }

  // Really shouldn't get here
  return false

}

export function createReview(date, grade, reviewHistory) {

  var newSm2Repetition = 0
  var newSm2Interval = 0
  var newSm2EasinessFactor = 2.5

  if (reviewHistory && reviewHistory.length > 0) {
    var reviewHistoryLast = reviewHistory[reviewHistory.length - 1]
    newSm2Repetition = reviewHistoryLast.sm2_repetition
    newSm2Interval = reviewHistoryLast.sm2_interval
    newSm2EasinessFactor = reviewHistoryLast.sm2_easiness_factor
  }


  if (grade < 3) {
    newSm2Repetition = 0
    newSm2Interval = 0
  } else {
    var qualityFactor = 5 - grade
    var potentialNewEasinessFactor = newSm2EasinessFactor + (0.1 - qualityFactor * (0.08 + qualityFactor * 0.02))
    if (potentialNewEasinessFactor < 1.3) {
      newSm2EasinessFactor = 1.3
    } else {
      newSm2EasinessFactor = potentialNewEasinessFactor
    }
    newSm2Repetition = newSm2Repetition + 1
    if (newSm2Repetition == 1) {
      newSm2Interval = 1
    } else if (newSm2Repetition == 2) {
      newSm2Interval = 6

    } else {
      var newIntervalDouble = Math.ceil((newSm2Repetition - 1) * newSm2EasinessFactor)

      newSm2Interval = Math.round(newIntervalDouble)
    }

  }

  if (grade == 3) {
    newSm2Interval = 0
  }


  var review = {
    completed_at: date,
    grade: grade,
    sm2_easiness_factor: newSm2EasinessFactor,
    sm2_interval: newSm2Interval,
    sm2_repetition: newSm2Repetition
  }

  return review;
}


export function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export function getOverdueNotes(topic, notes) {


  let overdueNotes = []

  for (let i = 0; i < notes.length; i++) {
    let note = notes[i]
    if (!isNoteExcluded(topic, note.id)) {
      if (!topic.review_history) {
        overdueNotes.push(note)
      } else if (!topic.review_history[note.id]) {
        overdueNotes.push(note)

      } else if (isNoteOverdue(topic.review_history[note.id])) {
        overdueNotes.push(note)

      }
    }
  }

  return overdueNotes
}

export function getOverdueNotesConsideringSpacedRepetitionSetting(topic, notes) {
  if (topic.auto_spaced_repetition != true) {
    return [];
  }

  return getOverdueNotes(topic, notes);


}

export function getNextSuggestedReview(topic) {

  // If we don't have spaced repetition on, return never
  if (topic.auto_spaced_repetition != true) {

    return null;
  }

  if (!topic.review_history) {
    topic.review_history = {}
  }

  // This handles nice things like doing considering the exclude list
  if (overdueCount(topic) > 0) {
    return new Date()
  }

  var minSuggestedDate = null;
  for (const [key, value] of Object.entries(topic.review_history)) {
    const noteId = key
    const noteReviewHistory = value

    if (!isNoteExcluded(topic, noteId)) {
      let noteSuggestedDate = getNextReviewDateForSort(noteReviewHistory)
      if (!minSuggestedDate || noteSuggestedDate < minSuggestedDate) {
        minSuggestedDate = noteSuggestedDate
      }
    }
  }

  return minSuggestedDate


}


export function getNextReviewDateForSort(reviewHistory) {
  if (!reviewHistory || reviewHistory.length < 1) {
    return new Date();
  }

  var sortedHistory = reviewHistory.sort(compareReviews)

  var lastReview = sortedHistory[reviewHistory.length - 1]

  const lastReviewTime = lastReview.completed_at

  if (lastReview.sm2_interval == 0) {
    return new Date(lastReviewTime);
  }

  if (lastReviewTime) {

    var nextReviewDate = new Date(lastReviewTime)

    nextReviewDate.setDate(nextReviewDate.getDate() + lastReview.sm2_interval)
    return nextReviewDate;
  }

    // Really shouldn't get here
  return new Date();

}



export function isNoteExcluded(topic, noteId) {
  
  var excludedNotes = topic.excluded_notes

  if (!excludedNotes || excludedNotes.length == 0) {
    return false;
  }

  return excludedNotes.includes(noteId);
}

export function getRecentDaysOfReviews(topic) {

  let uniqueDates = {}
  let maxNumberOfNotes = 0
  if (!topic || !topic.review_history) {
    return []
  }

  for (const [noteId, reviewHistory] of Object.entries(topic.review_history)) {

    for (let i = 0; i < reviewHistory.length; i++) {
      let review = reviewHistory[i]
      let recalled = review.grade > 3

      let completedAtDate = new Date(review.completed_at)

      let dateOnly = format(completedAtDate, 'MM/dd/yy')

      var existingData =  uniqueDates[dateOnly]
      if (!existingData) {
        uniqueDates[dateOnly] = {
          date: completedAtDate,
          recalled: recalled ? 1 : 0,
          forgotten: recalled ? 0 : 1,
        }
      } else {
        if (recalled) {
          existingData.recalled = existingData.recalled + 1
        } else {
          existingData.forgotten = existingData.forgotten + 1

        }
        uniqueDates[dateOnly] = existingData


      }

      const maxNotesReviewedInADay = uniqueDates[dateOnly].recalled + uniqueDates[dateOnly].forgotten
      if (maxNotesReviewedInADay > maxNumberOfNotes) {
        maxNumberOfNotes = maxNotesReviewedInADay
      }

    }
  }

  let dayValues = Object.values(uniqueDates)
  let sorted = dayValues.sort(function(a, b) {
    return b.date - a.date;
  })
  let first = sorted.slice(0, 6)


  return {maxNumberOfNotes, recentDays: first}

}


export function getTopicStats(topic) {


  if (!topic || !topic.review_history) {
    return 0
  }

  let uniqueDates = {}
  let notesReviewed = 0

  for (const [noteId, reviewHistory] of Object.entries(topic.review_history)) {

    for (let i = 0; i < reviewHistory.length; i++) {
      let review = reviewHistory[i]


      let completedAtDate = new Date(review.completed_at)

      let dateOnly = format(completedAtDate, 'MM/dd/yy')
      uniqueDates[dateOnly] = true

      notesReviewed = notesReviewed + 1

    }
  }

  return { uniqueDates: Object.keys(uniqueDates).length, notesReviewed}

}

export function getNextReviewDateString(topic) {
  
  var nextReviewDate = "--"

  if (!topic) {
    return nextReviewDate;
  }

  if (topic.auto_spaced_repetition == true) {
    var nextSuggestedReview = getNextSuggestedReview(topic);

    if (nextSuggestedReview) {

      if (!(nextSuggestedReview > new Date())) {
        nextReviewDate = "ASAP"
      } else {
        nextReviewDate = format(nextSuggestedReview, 'MMM d')
      }

    }

  }

  return nextReviewDate;
}

export function clearStateAfterUserChanged(dispatch) {
  dispatch({ type: RESET_STORE, payload: [] })
   // dispatch({ type: CLEAR_NOTES, payload: [] })
  // dispatch({ type: CLEAR_TOPICS, payload: { topics: []} });
  // dispatch(clearSelectedTopic());
  // dispatch(setReviewInitialState())
}


export async function getSearchKeys(dispatch) {
  const functionRef = firebase.app().functions("us-central1").httpsCallable('getSearchKeyCallable');
  const { data } = await functionRef({});

  dispatch(setSearchKeys(data.user_key, data.sharing_key))
}