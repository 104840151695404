import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/remote-config'
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
  

firebase.firestore();

firebase.firestore().enablePersistence()
  .catch(function(err) {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });



export default firebase;

const firebaseAnalytics = firebase.analytics();

export function analytics() {
  return firebaseAnalytics;
}

const firebaseRemoteConfig = firebase.remoteConfig();
firebaseRemoteConfig.settings = {
    fetchTimeMillis: 1000, // One second before timing out while fetching
};


// Production topics
if (false) {
  firebaseRemoteConfig.defaultConfig = ({
    'sharedHowToTopic': 'Uus2faTdYHdhLdC7z03o',

    'sharedExampleTopics': '04Y4UEhBWMevKx3fcUOK,03uU0oHU2dMcYwb6wQaX',
  });
}

firebaseRemoteConfig.defaultConfig = ({
    'sharedHowToTopic': '2ys9f4INInOnHSmRvEwL',

    'sharedExampleTopics': '04Y4UEhBWMevKx3fcUOK,03uU0oHU2dMcYwb6wQaX',
    'showSubscribeViewOnWelcome': true,
    'showReviewModuleInWelcome': true,
    'showPremiumButtonOnWebSidebar': true

  });

export function remoteConfig() {
    return firebaseRemoteConfig;
}

