import { sendAmplitudeData } from "./amplitude"
import { analytics } from "firebase"

export function logEvent(eventName, properties) {
  console.log("Logging event: " + eventName + " - " + JSON.stringify(properties))
  sendAmplitudeData(eventName, properties)
  analytics().logEvent(eventName, properties)
}

export function logError(error) {
    console.log("Logging error: " + JSON.stringify(error))
  }
