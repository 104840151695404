import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import { Button, Label } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../app/common/modals/modalReducer';
import { registerInFirebasePasswordless } from '../../app/firestore/firebaseService';
import { useState } from 'react';

export default function LoginForm({notClosable}) {
    const dispatch = useDispatch();

    const [emailedSubmitted, setEmailSubmitted] = useState(false);

    return (
        <ModalWrapper notClosable={notClosable} size='mini' header={emailedSubmitted ? 'Check your email!' : 'Sign in to Notefuel'}>
            { emailedSubmitted ? 
           <div style={{}}>
                   We sent you a link to sign in automatically.
           </div>
           :
           (<>
            <div style={{marginBottom: 20}}>
            Provide the email associated with your account and we'll send you a link to sign in automatically.
            </div>

            <Formik
                initialValues={{email: ''}}
                validationSchema={Yup.object({
                    email: Yup.string().required().email()
                })}
                onSubmit={async (values, {setSubmitting, setErrors}) => {
                    try {
                        await registerInFirebasePasswordless(values);
                        setSubmitting(false);
                        setEmailSubmitted(true);

                        // dispatch(closeModal());
                    } catch (error) {
                        setErrors({auth: 'Email is invalid.'});
                        setSubmitting(false);
                    }
                }}
            >
                {({isSubmitting, isValid, dirty, errors}) => (
                    <Form className='ui form'>
                        <MyTextInput autoFocus name='email' placeholder='Email Address' />
                        {errors.auth && <Label basic color='red' style={{marginBottom: 10}} content={errors.auth} />}
                        <Button className="submitButton"
                            style={{ color: "white", height: 44, borderRadius: 22}}
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type='submit'
                            fluid
                            size='large'
                            content='Login'
                        />

                    </Form>
                )}
            </Formik>
            </>)}
        </ModalWrapper>
    )
}